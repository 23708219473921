import Container from './objects/container';

/**
 * Менеджер аккордионов для единичной инициализации на странице и управления всеми аккордионами
 * Собирает аккордионы и отслеживает появление новых при изменениях на странице
 * Отслеживает ресайз страницы и передает его всем аккордионам для передачи в елементы
 *
 * ПОБОЧНО:
 * AccordionOpen - событие, отправляемое на документ, при разворачивании элемента аккордиона
 *  Передаваемые параметры:
 *    container - аккордион
 *    element - елемент аккордиона
 *
 * AccordionClose - событие, отправляемое на документ, при сворачивании элемента аккордиона
 * Передаваемые параметры:
 *    container - аккордион
 *    element - елемент аккордиона
 *
 * О работе с аккордионом и элементами смотрим классы Container и Element
 */
class Accordion {
  constructor() {
    this.containers = [];
    this.init();
    this.accordionListeners();
  }

  /**
   * Инициализация аккордионов при создании или изменении
   */
  init() {
    this.collectContainers();
    this.containerListeners();
    this.setInitialized();
  }

  /**
   * Сбор всех аккордионов на странице
   */
  collectContainers() {
    const containers = document.querySelectorAll('[data-accordion-container]:not([data-initialized="true"])');
    containers.forEach((containerElement) => {
      this.containers.push(new Container(containerElement));
    });
  }

  /**
   * Отслеживание менеджером изменений и ресайза
   */
  accordionListeners() {
    const instance = this;

    document.addEventListener('DOMContentMutated', (e) => {
      // Переинициализация текущих контейнеров для отслеживания в них новых элементов
      instance.containers.forEach(container => container.init());

      setTimeout(() => {
        instance.containers.forEach(container => container.onResize());
      }, 250);

      // Переинициализация менеджера для отслеживания новых контейнеров
      this.init();
    });

    window.addEventListener('resize', () => {
      // Передача ресайза контенерам
      instance.containers.forEach(container => container.onResize());
    });
  }

  /**
   * Помечаем контейнеры как обработанные
   */
  setInitialized() {
    this.getNotInitContainers().forEach(container => container.containerElement.dataset.initialized = "true");
  }

  /**
   * Взять все не аккордионы, не помеченные как обработанные
   */
  getNotInitContainers() {
    return this.containers.filter(container => !container.containerElement.dataset.initialized);
  }

  /**
   * (Редкая потребность)
   * Слушатели
   * - слушатели елементов контейнера на открытие и закрытие элементов для передачи события на документ
   */
  containerListeners() {
    const instance = this;
    this.getNotInitContainers().forEach((container) => {
      instance.transferEvent(container, 'ContainerElementOpen', 'AccordionOpen');
      instance.transferEvent(container, 'ContainerElementClose', 'AccordionClose');
    });
  }

  /**
   * Отследить событие listenEvent у container и отправить dispatchEvent на документ
   */
  transferEvent(container, listenEvent, dispatchEvent) {
    container.containerElement.addEventListener(listenEvent, (e) => {
      const eventElement = e.detail.element;
      document.dispatchEvent(new CustomEvent(dispatchEvent, {
        detail: {
          container: container.containerElement,
          element: eventElement
        }
      }))
    });
  }

}

export default Accordion;