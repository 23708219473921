import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const container = document.querySelector('[data-infrastructure-slider-container]');

    if (container) {
        const leftArrow = document.querySelector('[data-infrastructure-slider-left]');
        const rightArrow = document.querySelector('[data-infrastructure-slider-right]');
        const slider = new Swiper(document.querySelector('[data-infrastructure-slider]'), {
            modules: [Navigation],
            slidesPerView: "auto",
            breakpoints: {
                0: {
                    spaceBetween: 14,
                },
                768: {
                    spaceBetween: 18,
                },
                1200: {
                    spaceBetween: 30,
                },
            },
            navigation: {
                prevEl: leftArrow,
                nextEl: rightArrow,
            },
        });
    }
}

onDomReady(init);