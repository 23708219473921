import { mapState, mapActions } from 'pinia'
import { useFilterStore } from "../../stores/filter";

const mixin = {
  props: {
    filterKey: String,
    field: Object,
    className: String
  },
  computed: {
    ...mapState(useFilterStore, [
      'findValueItem'
    ]),
    blockClasses() {
      const classes = [];
      if (this.className) {
        classes.push(this.className);
      }
      if (this.field.class) {
        classes.push(this.field.class);
      }
      classes.push('filter-field');
      return classes;
    },
    valueItem() {
      return this.findValueItem(this.filterKey, this.field.storeKey);
    }
  },
  methods: {
    elementClasses(element) {
      return this.blockClasses.map((className) => `${className}__${element}`);
    }
  }
};

export const fieldMixin = mixin;