export default class Balloon {
  constructor(selector, settings) {
    this.container = selector;
    this.settings = settings;
    this.link = this.container.querySelector('[data-balloon-link]');
    this.balloonElement = this.container.querySelector('[data-balloon-element]');

    if (!this.balloonElement) {
      return;
    }

    this.eventListeners();
  }

  eventListeners() {
    this.container.addEventListener('mouseenter', () => {
      this.showBalloon();
    });
    this.container.addEventListener('mouseleave', () => {
      this.hideBalloon();
    });
  }

  showBalloon() {
    this.balloonElement.classList.add('_visible');
    this.renderBalloon();
  }

  hideBalloon() {
    this.balloonElement.classList.remove('_visible');
    this.clearBalloon();
  }

  renderBalloon() {
    const centerOfVisibleContainer = [
      window.innerWidth / 2,
      window.innerHeight / 2
    ];

    // размеры
    const balloonHeight = this.balloonElement.offsetHeight;
    const containerHeight = this.container.offsetHeight;
    const containerHeightHalf = containerHeight / 2;

    // Вычисляем куда будет вылезать высплывашка
    const centerOfObject = this.getContainerPosition();
    // Вылезает влево/вправо относительно плейсмарка
    const xDirection = centerOfObject[0] > centerOfVisibleContainer[0] ? 'to-left' : 'to-right';
    // Вылезает вверх/вниз относительно плейсмарка
    const yDirection = centerOfObject[1] < centerOfVisibleContainer[1] ? 'to-bottom' : 'to-top';

    // Вычисляем границы балуна с учетом рассчитанного смещения
    let balloonTopBorder = 0;
    let balloonBottomBorder = 0;

    if (yDirection === 'to-top') {
      balloonTopBorder = (centerOfObject[1] + containerHeightHalf) - balloonHeight;
      balloonBottomBorder = balloonTopBorder + balloonHeight;
    } else if (yDirection === 'to-bottom') {
      balloonBottomBorder = (centerOfObject[1] - containerHeightHalf) + balloonHeight;
      balloonTopBorder = balloonBottomBorder - balloonHeight;
    }

    // Проверяем, не вылетает ли балун за границы видимой области (с учетом отступа до границы)
    // Если вылетаем - вычисляем на сколько его надо сдвинуть по оси y
    // Отрицательное значение - сдвиг вверх, положительное - вниз
    let yOffset = 0;
    if (balloonTopBorder < 0) {
      yOffset = balloonTopBorder;
    } else if (balloonBottomBorder > window.innerHeight) {
      yOffset = balloonBottomBorder - window.innerHeight;
    }

    if (xDirection === 'to-left') {
      this.balloonElement.style.right = this.toStylePx((window.innerWidth - centerOfObject[0]) + containerHeightHalf);
    } else if (xDirection === 'to-right') {
      this.balloonElement.style.left = this.toStylePx(centerOfObject[0] + containerHeightHalf);
    }

    if (yDirection === 'to-top') {
      this.balloonElement.style.bottom = this.toStylePx((window.innerHeight - centerOfObject[1]) - containerHeightHalf + yOffset);
    } else if (yDirection === 'to-bottom') {
      this.balloonElement.style.top = this.toStylePx((centerOfObject[1] - containerHeightHalf) - yOffset);
    }

    if (yOffset !== 0) {
      const pinYOffset = Math.abs(yOffset);
      this.balloonElement.style.setProperty('--pin-y-offset', this.toStylePx(this.settings.balloonPinGap - pinYOffset));
    }

    this.balloonElement.classList.remove(...['to-left', 'to-right', 'to-top', 'to-bottom']);
    this.balloonElement.classList.add(xDirection, yDirection);
  }

  getContainerPosition() {
    const bounds = this.container.getBoundingClientRect();
    const containerTop = bounds.top;
    const containerLeft = bounds.left;
    const containerHeight = this.container.offsetHeight;
    const containerWidth = this.container.offsetWidth;
    return [
      containerLeft + containerWidth / 2,
      containerTop + containerHeight / 2,
    ];
  }

  toStylePx(value) {
    return value !== undefined ? value + 'px' : ''
  }

  clearBalloon() {
    this.balloonElement.style.left = '';
    this.balloonElement.style.right = '';
    this.balloonElement.style.top = '';
    this.balloonElement.style.bottom = '';
    this.balloonElement.style.setProperty('--pin-y-offset', this.toStylePx(this.settings.balloonPinGap));
  }
}