<template>
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6.5 6L12 1" stroke="#C5C5C5" stroke-width="2"/>
  </svg>
</template>

<script>
  export default {
    name: "Arrow"
  }
</script>

<style scoped>

</style>