import {scrollToTarget, scrollToPosition} from "../../components/scroll/scroll";

document.querySelectorAll('[data-switchable-accordion-link]').forEach((switchableAccordionLink) => {
  switchableAccordionLink.addEventListener('click', (e) => {
    e.preventDefault();
    const idSelector = switchableAccordionLink.getAttribute('href');
    const element = document.querySelector(idSelector);
    if (element) {
      if (!element.classList.contains('_opened')) {
        const internalLink = element.querySelector('[data-accordion-link]');
        if (internalLink) {
          internalLink.click();
        }
      }
    }
  });
});

// ElementOpen
document.querySelectorAll('[data-switchable-accordion-element]').forEach((switchableAccordionElement) => {
  switchableAccordionElement.addEventListener('ElementOpen', () => {
    const id = switchableAccordionElement.id;
    const link = document.querySelector(`[data-switchable-accordion-link][href='#${id}']`);
    document.querySelectorAll('[data-switchable-accordion-link]').forEach((element) => {
      element.closest('li').classList.remove('_active');
    });
    scrollToTarget(link.closest('[data-accordion-container]'), -100);
    link.closest('li').classList.add('_active');
  });
});