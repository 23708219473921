<template>
    <div :class="blockClasses">
        <label v-if="field.label" :class="elementClasses('label')">{{ field.label }}</label>
        <div :class="elementClasses('wrapper')" v-if="valueItem.value">
            <select v-model="valueItem.value" :class="elementClasses('select')" :disabled="field.disabled">
                <option v-for="(choice, index) in field.choices" :value="choice.key" :disabled="choice.disabled">
                    {{ choice.label }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import { fieldMixin } from "./fieldMixin";

export default {
  name: "DropdownField",
  mixins: [
    fieldMixin
  ],
  methods: {
    checkValue() {
      if (!this.valueItem.value && this.field.choices.length) {
        this.valueItem.value = this.field.choices[0].key;
      }
    },
  },
  mounted() {
    this.checkValue();
  },
  beforeUpdate() {
    this.checkValue();
  }
}
</script>

<style lang="scss">

</style>