function fillUrlWithFormData(url, formData) {
    if (formData) {
        let query = [];
        for (let pair of formData.entries()) {
            query.push(encodeURIComponent(pair[0]) + '=' + encodeURIComponent(pair[1]));
        }
        let queryString = query.join('&');
        if (queryString) {
            url += (url.includes('?') ? '': '?') + queryString;
        }
    }
    return url;
}

export {
    fillUrlWithFormData
}