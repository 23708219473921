export class ScrollSlider {
    constructor(rootElement)
    {
        this.rootElement = rootElement;
        this.lastUpdateTopPosition = null;
        this.rootTop = undefined;
        this.rootHeight = undefined;
        this.slides = [];
        this.activeSlide = null;
        this.initSlides();
        this.bind();
    }

    bind()
    {
        window.addEventListener('scroll', () => {
            this.onScroll();
        });
    }

    initSlides()
    {
        this.rootElement.querySelectorAll('[data-about-slide]').forEach((slide) => {
            this.slides.push(slide);
        });
    }

    updateRootPositionInfo()
    {
        const rect = this.rootElement.getBoundingClientRect();
        this.rootTop = rect.top
        + document.documentElement.scrollTop;
        this.rootHeight = rect.height;
    }

    getRootPosition()
    {
        const now = new Date();
        if (!this.lastUpdateTopPosition || (now - this.lastUpdateTopPosition) > 500) {
            this.lastUpdateTopPosition = now;
            this.updateRootPositionInfo();
        }
        return {
            top: this.rootTop,
            height: this.rootHeight,
        };
    }

    onScroll()
    {
        const position = this.getRootPosition();
        const pageHeight = window.innerHeight;
        let scrolled = document.documentElement.scrollTop - position.top;
        const maxScroll = position.height - pageHeight;
        if (scrolled < 0) {
            scrolled = 0;
        }
        if (scrolled > maxScroll) {
            scrolled = maxScroll;
        }
        const activeSlide = Math.floor(scrolled / pageHeight);
        const percent = (scrolled - activeSlide * pageHeight) / pageHeight * 100;

        const currentSlideElement = this.slides[this.activeSlide];
        const activeSlideElement = this.slides[activeSlide];
        let nextSlideElement = null;
        if (activeSlide + 1 < this.slides.length) {
            nextSlideElement = this.slides[activeSlide + 1];
        }

        this.setPercent(activeSlideElement, percent);
        if (this.activeSlide !== null) {
            if (this.activeSlide > activeSlide) {
                this.setPercent(currentSlideElement, 0);
            } else if (this.activeSlide < activeSlide) {
                this.setPercent(currentSlideElement, 100);
            }
        }

        if (nextSlideElement) {
            this.setPercent(nextSlideElement, percent, 'next-percent');
        }

        this.setActiveSlide(activeSlide);
    }

    setActiveSlide(newActiveSlide)
    {
        if (newActiveSlide === this.activeSlide) {
            return;
        }
        const newActiveSlideElement = this.slides[newActiveSlide];

        let direction = 'down';
        if (newActiveSlide < this.activeSlide) {
            direction = 'up';
        }

        if (this.activeSlide !== null) {
            const oldActiveSlideElement = this.slides[this.activeSlide];
            oldActiveSlideElement.classList.remove(
                '_active',
                '_active_up',
                '_active_down',
                '_inactive',
                '_inactive_up',
                '_inactive_down',
            );
            oldActiveSlideElement.classList.add(
                '_inactive',
                `_inactive_${direction}`,
            );
        }
        newActiveSlideElement.classList.remove(
            '_active',
            '_active_up',
            '_active_down',
            '_inactive',
            '_inactive_up',
            '_inactive_down',
        );
        newActiveSlideElement.classList.add(
            '_active',
            `_active_${direction}`,
        );

        this.activeSlide = newActiveSlide;
    }

    setPercent(element, percent, name)
    {
        if (!name) {
            name = 'percent';
        }
        element.style.setProperty(`--${name}`, `${percent}%`);
        element.style.setProperty(`--${name}-val`, percent);
        element.style.setProperty(`--${name}-rev`, `${100 - percent}%`);
        element.style.setProperty(`--${name}-rev-val`, 100 - percent);
    }
}