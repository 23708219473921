import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Swiper} from "swiper";

onDomReady(() => {
  const containerElement = document.querySelector('[data-advantages-accordion-container]');
  if (containerElement) {
    const accordionList = containerElement.querySelector('[data-advantages-accordion-list]');
    const accordionItems = containerElement.querySelectorAll('[data-accordion-element]');
    const imagesElement = document.querySelector('[data-advantages-accordion-slider]');

    const arrowLeft = containerElement.querySelector('[data-advantages-accordion-slider-left]');
    const arrowRight = containerElement.querySelector('[data-advantages-accordion-slider-right]');

    const pagination = containerElement.querySelector('[data-advantages-accordion-slider-pagination]');

    let activeTab = undefined;

    const imagesSlider = new Swiper(imagesElement, {
      modules: [Navigation, Pagination],
      preventInteractionOnTransition: true,
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        prevEl: arrowLeft,
        nextEl: arrowRight
      }
    });

    accordionItems.forEach((accordionItem) => {
      accordionItem.addEventListener('click', () => {
        const id = accordionItem.dataset.accordionElement;
        const currentSlide = Array.from(imagesSlider.slides).find((slide) => {
          return parseInt(slide.dataset.accordionSlide, 10) === parseInt(id, 10);
        })
        imagesSlider.slideTo(imagesSlider.slides.indexOf(currentSlide));
      })
    });

    imagesSlider.on('slideChange', () => {
      const currentSlide = imagesSlider.slides[imagesSlider.realIndex];
      accordionItems.forEach((accordionItem) => {
        const id = accordionItem.dataset.accordionElement;
        if (parseInt(id, 10) === parseInt(currentSlide.dataset.accordionSlide, 10)) {
          accordionItem.classList.add('_active');
          activeTab = accordionItem;
        } else {
          accordionItem.classList.remove('_active');
        }
      })

      if (window.innerWidth < 1200) {
        const gridSpacer = getComputedStyle(document.documentElement).getPropertyValue('--grid-spacer');
        const gridSpacerValue = parseInt(gridSpacer, 10);
        accordionList.scrollTo({left: (activeTab.offsetLeft - gridSpacerValue), behavior: 'smooth'});
      }

    })
  }
})