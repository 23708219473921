import { Genplan } from './genplan';
import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

function init(isReady) {
  setTimeout(() => {
    document.querySelectorAll('[data-gen]:not([data-initialized="true"])').forEach((htmlElement) => {
      if (isReady && htmlElement.dataset.disableOnReady === 'true') {
        return;
      }
      new Genplan(htmlElement, {
        // Отступ точки от края видимой области
        poiGap: 0,
        // Отступ балуна от края видимой области
        balloonGap: 0,
        // Проверка на то, может ли пользователь скроллить включается ниже данного значения ширины
        // экрана
        scrollableToWidth: 1200,
        // Высота мини-карты
        minimapHeight: 50,
        // contain / cover - аналогию, я думаю, вы поняли
        size: htmlElement.dataset.size ? htmlElement.dataset.size : 'cover',
        // Важные точки интереса (объекты) для скролл-режима
        // они определяют какую часть генплана показывать сначала в мобильном режиме
        // проще говоря на сколько скроллить изначально в мобильном (скроллящемся) режиме
        center: htmlElement.dataset.center ? htmlElement.dataset.center : null,
        scrollableImportantObjectTypes: ['important'],
        type: htmlElement.dataset.type ? htmlElement.dataset.type : null,
      });
      htmlElement.dataset.initialized = 'true';
    });
    if (window.location.hash) {
      document.dispatchEvent(new CustomEvent('request-openen-genplan-scene', {
        detail: {
          sceneName: window.location.hash.substring(1),
        },
      }));
    }
  }, 2000);
}


onDomReady(() => {
  init(true);
})

document.addEventListener('smart-tabs-handle', () => {
  init();
});