import {onDomReady} from "../../components/dynamic/observer";
import {EffectCreative, EffectFade, Navigation, Pagination, Swiper} from "swiper";

onDomReady(() => {
  const element = document.querySelector('[data-advantages-slider]');
  if (element) {
    const textElement = element.querySelector('[data-advantages-slider-text]');
    const imagesElement = element.querySelector('[data-advantages-slider-images]');

    const textSlider = new Swiper(textElement, {
      modules: [Navigation, Pagination, EffectCreative],
      preventInteractionOnTransition: true,
      speed: 1000,
      rewind: true,
      effect: 'creative',
      creativeEffect: {
        prev: {
          translate: [0, 0, -1],

        },
        next: {
          translate: [0, 0, 0],
        },
      },
      pagination: {
        el: element.querySelector('[data-advantages-slider-pagination]'),
        type: 'custom',
        renderCustom: function(swiper, current, total) {
          return '<div class="pagination__current">' + ('0' + current).slice(-2) + '</div>'
                  + '<div class="pagination__delimiter">' + '/' + '</div>'
                  + '<div class="pagination__total">' + ('0' + total).slice(-2) + '</div>';
        },
      },
      navigation: {
        prevEl: element.querySelector('[data-advantages-slider-left]'),
        nextEl: element.querySelector('[data-advantages-slider-right]')
      }
    });

    const imagesSlider = new Swiper(imagesElement, {
      modules: [EffectCreative],
      preventInteractionOnTransition: true,
      speed: 1000,
      rewind: true,
      effect: 'creative',
      creativeEffect: {
        prev: {
          translate: [0, 0, -1],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      },
    })

    if (textSlider && imagesSlider) {
      textSlider.on('activeIndexChange', function () {
        imagesSlider.slideTo(textSlider.activeIndex);
      });

      imagesSlider.on('activeIndexChange', function () {
        textSlider.slideTo(imagesSlider.activeIndex);
      });
    }
  }
})