import { mapState, mapActions } from 'pinia'
import { useFilterStore } from "../../stores/filter";

const mixin = {
  props: {
    filterKey: String,
    fieldsetKey: String
  },
  computed: {
    ...mapState(useFilterStore, [
      'fieldsByFieldset',
      'findFieldset',
      'getValues'
    ]),
    fields() {
      return this.fieldsByFieldset(
        this.filterKey,
        this.fieldsetKey
      );
    },
    fieldset() {
      return this.findFieldset(
        this.filterKey,
        this.fieldsetKey
      );
    },
    blockClasses() {
      const classes = [];
      if (this.className) {
        classes.push(this.className);
      }
      if (this.fieldset && this.fieldset.class) {
        classes.push(this.fieldset.class);
      }
      classes.push('filter-fieldset');
      return classes;
    },
    values() {
      return this.getValues(this.filterKey);
    },
  },
  // Получение списка полей для рендера
  //
  methods: {
    elementClasses(element) {
      return this.blockClasses.map((className) => `${className}__${element}`);
    }
  },
  mounted() {
    document.addEventListener('GenplanSceneChanged', (e) => {
      const sceneData = e.detail.sceneName.split('_');
      if (!sceneData) {
        return;
      }

      if (sceneData[0] === 'genplan') {
        if (this.values['quarter']) {
          this.values['quarter'].splice(0, this.values['quarter'].length);
        }
        if (this.values['building']) {
          this.values['building'].splice(0, this.values['building'].length);
        }
      } else if (sceneData[0] === 'quarter') {
        if (this.values['quarter'] && !this.values['quarter'].includes(sceneData[1])) {
          this.values['quarter'].push(sceneData[1]);
        }
        if (this.values['building']) {
          this.values['building'].splice(0, this.values['building'].length);
        }
      } else if (sceneData[0] === 'building') {
        if (this.values['building'] && !this.values['building'].includes(sceneData[1])) {
          this.values['building'].push(sceneData[1]);
        }
      }
    });
  }
};

export const fieldsetMixin = mixin;