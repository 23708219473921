import Balloon from "./Balloon";
import {onDomReady} from "../../components/dynamic/observer";

const init = () => {
  document.querySelectorAll('[data-balloon-item]').forEach((item) => {
    new Balloon(item, {
      balloonPinGap: 4,
    });
  })
};

onDomReady(() => init());