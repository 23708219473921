import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const swipeSliderElement = document.querySelector('[data-swipe-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = document.querySelector('[data-swipe-slider-left]');
    const rightArrow = document.querySelector('[data-swipe-slider-right]');

    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation],
        slidesPerView: "auto",
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
    });
}

onDomReady(init);