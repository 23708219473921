document.querySelectorAll('[data-print-link]').forEach((printLink) => {
    printLink.addEventListener('click', () => {
        window.print();
    });
});

/**
 *
 * Собираем кнопки для печати и по нажатию на них печатаем нужную модалку
 *
 * У кнопки data-print-id
 * У элемента id
 * Они должны совпадать. Смотрим у кнопки и по нему ищем
 *
 * */

import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

// TODO: дать скачивать pdf на мобиле

function init() {
    document.querySelectorAll('[data-print-link]')
        .forEach((item) => {
            item.onclick = function () {
                let id = item.dataset.printId;
                printElement(document.getElementById(id));
            }
        });
}

function printElement(elem) {
    const domClone = elem.cloneNode(true);

    let printSection = document.getElementById("printSection");

    if (!printSection) {
        printSection = document.createElement("div");
        printSection.id = "printSection";
        document.body.appendChild(printSection);
    }

    printSection.innerHTML = "";
    printSection.appendChild(domClone);
    window.print();
}

onDomReady(() => init());
onDomChanges(() => init());