<template>
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7352 3.26526L4.67661 10.7824L0.29221 6.88513L1.62094 5.39031L4.43657 7.89309L9.178 2.01021L10.7352 3.26526Z" fill="black"/>
  </svg>
</template>

<script>
  export default {
    name: "CheckMarkBlack"
  }
</script>

<style scoped>

</style>