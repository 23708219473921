import {onDomReady} from "../../components/dynamic/observer";

export class Changer {
  constructor() {
    this.mainGenplan = document.querySelector('[data-main-gen]');
    this.buildingLinks = document.querySelectorAll('[data-building-link]');
    this.buildingBackLinks = document.querySelectorAll('[data-building-back-link]');
    this.buildingPlans = document.querySelectorAll('[data-building-id]');
    this.genChoicers = document.querySelectorAll('[data-gen-choice]');
    this.chessChoicers = document.querySelectorAll('[data-chess-choice]');
    this.infraChangers = document.querySelectorAll('[data-infra-changer]');
    this.bindEvents()
  }

  bindEvents() {
    this.buildingLinks.forEach((link) => {
      link.addEventListener('click', () => {
        this.onBuildingClick(link.dataset.buildingLink);
      })
    })

    this.buildingBackLinks.forEach((link) => {
      link.addEventListener('click', () => {
        this.onBackBuildingClick(link.dataset.buildingBackLink);
      })
    })

    this.genChoicers.forEach((link) => {
      link.addEventListener('click', () => {
        this.onBuildingChoice(link);
      })
    })

    this.chessChoicers.forEach((link) => {
      link.addEventListener('click', () => {
        this.onGridChoice(link);
      })
    })

    this.infraChangers.forEach((el) => {
      el.addEventListener('change', () => {
        this.onInfraChange(el)
      })
    })
  }

  onBuildingClick(buildingId) {
    this.buildingPlans.forEach((plan) => {
      if (plan.dataset.buildingId === buildingId) {
        this.mainGenplan.classList.add('_hidden')
        plan.classList.remove('_hidden')
      }
    })
  }

  onBackBuildingClick(buildingId) {
    this.buildingPlans.forEach((plan) => {
      if (plan.dataset.buildingId === buildingId) {
        this.mainGenplan.classList.remove('_hidden')
        plan.classList.add('_hidden')
      }
    })
  }

  onBuildingChoice(element) {
    element.closest('[data-building-id]').querySelector('[data-gen]')
        .classList.remove('_hidden');
    element.closest('[data-building-id]').querySelector('[data-chess]')
        .classList.add('_hidden');
    this.buttonsChange(element);
  }

  onGridChoice(element) {
    element.closest('[data-building-id]').querySelector('[data-gen]')
        .classList.add('_hidden');
    element.closest('[data-building-id]').querySelector('[data-chess]')
        .classList.remove('_hidden');

    this.buttonsChange(element);
  }

  buttonsChange(element)
  {
    element.closest('[data-building-id]').querySelectorAll('.gen-building__tabs-item')
        .forEach((e) => {
          e.classList.toggle('_active')
        });
    element.closest('[data-building-id]').querySelector('.gen-building__tabs-list')
        .classList.toggle('_gray')
  }

  onInfraChange(element) {
    const genplanEl = element.closest('.genplan__scene')
    genplanEl.querySelectorAll('.c-genplan__placemark').forEach((e) => {
      e.classList.toggle('_hidden')
    })
  }
}

onDomReady(() => {
    new Changer();
});