export class MapObject {
    constructor(element)
    {
        this.settings = Object.assign({
            lat: 0,
            lng: 0,
            category: 'shopping',
            name: '',
            color: '%238EBA3E',
            iconHover: '',
            icon: '',
        }, element.dataset);
        this.placemark = undefined;
        this.uid = Math.random().toString(36).slice(-5);
    }

    getUid()
    {
        return this.uid;
    }

    getLat()
    {
        return parseFloat(this.settings.lat);
    }

    getLng()
    {
        return parseFloat(this.settings.lng);
    }

  // getCategory() {
  //   return this.settings.category;
  // }

    getIcon()
    {
        return this.settings.icon;
    }

    getIconHover()
    {
        return this.settings.iconHover ? this.settings.iconHover : this.getIcon();
    }

    getName()
    {
        return this.settings.name;
    }

  // getColor() {
  //   return this.settings.color;
  // }

    setPlacemark(placemark)
    {
        this.placemark = placemark;
    }

    getPlacemark()
    {
        return this.placemark;
    }
}