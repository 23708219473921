import { onDomChanges } from '../../components/dynamic/observer';

function getTimeRemaining(endtime) {
  const t = Date.parse(endtime) - Date.parse(new Date());
  const minutes = Math.floor((t / 1000) / 60);
  const seconds = Math.floor((t / 1000) % 60);
  return {
    total: t,
    minutes,
    seconds,
  };
}

function initializeClock(timeoutElement, id, endtime) {
  let isResolve = false;
  document.addEventListener('resolve-button-click', (e) => {
    isResolve = true;
  });

  const timerElem = document.createElement('span');
  timeoutElement.appendChild(timerElem);
  function updateClock() {
    const t = getTimeRemaining(endtime);
    const timeinterval = setTimeout(updateClock, 1000);

    timerElem.innerHTML = '<span>' + (`0${t.minutes}`).slice(-2) + ':' + (`0${t.seconds}`).slice(-2) + '&nbsp;секунды</span>';

    if (isResolve === true) {
      clearInterval(timeinterval);
    } else if (t.total <= 0) {
      clearInterval(timeinterval);
      const closer = document.querySelector('.modal__closer');
      closer.click();
    }
  }

  updateClock();
}

onDomChanges(() => {
  const element = document.querySelector('[data-timeout]');
  if (element) {
    const data = element.dataset;
    const timeout = Number(data.timeout.toString());
    const dateNow = new Date();
    const dateEnd = dateNow.setSeconds(dateNow.getSeconds() + timeout);
    const deadline = new Date(dateEnd);
    initializeClock(element, 'clock', deadline);
  }
});