import {onDomReady} from "../../components/dynamic/observer";

function init() {
    const modal = document.querySelector('[data-fav-success]');
    if (!modal) {
        return;
    }

    const closer = document.querySelector('[data-fav-success-closer]');
    const modalText = document.querySelector('[data-fav-success-text]');

    closer.addEventListener('click', () => {
        if (modal.classList.contains('_opened')) {
            modal.classList.remove('_opened');
        }
    })

    document.addEventListener('favoriteToggle', (e) => {
        if (e.detail.add) {
            modalText.textContent='добавили квартиру в Избранное.';
        } else {
            modalText.textContent='удалили квартиру из Избранного.';
        }

        if (!modal.classList.contains('_opened')) {
            modal.classList.add('_opened');
        }

        setTimeout(() => {
            if (modal.classList.contains('_opened')) {
                modal.classList.remove('_opened');
            }
        }, 20000)
    });
}

onDomReady(() => {
    init();
});