import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class DisableSubmitButtons {
    constructor(submitButtons) {
        this.submitButtons = submitButtons;
        this.addListener();
    }

    addListener() {
        this.submitButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                this.disableButton(e.target);
                this.enableButton(e.target);
            });
        });
    }

    disableButton(button) {
        setTimeout(() => {
           button.disabled = true;
        }, 100);
    }

    enableButton(button) {
        setTimeout(() => {
            button.disabled = false;
        }, 1000)
    }
}

onDomReady(() => {
    new DisableSubmitButtons(document.querySelectorAll('[data-submit-button]'));
})

onDomChanges(() => {
    new DisableSubmitButtons(document.querySelectorAll('[data-submit-button]'));
})