import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Swiper} from "swiper";

onDomReady(() => {
  const element = document.querySelector('[data-pipeline-slider-container]');
  if (element) {
    const sliderEl = element.querySelector('[data-pipeline-slider]');

    const slider = new Swiper(sliderEl, {
      modules: [Navigation, Pagination],
      preventInteractionOnTransition: true,
      speed: 500,
      pagination: {
        el: element.querySelector('[data-pipeline-slider-pagination]'),
        type: 'progressbar',
      },
      navigation: {
        prevEl: element.querySelector('[data-pipeline-slider-left]'),
        nextEl: element.querySelector('[data-pipeline-slider-right]')
      },
      breakpoints: {
        375: {
          slidesPerView: 1,
          spaceBetween: 14,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 18,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          refreshFsLightbox();
        },
      },
    });
  }
})