import {onDomReady} from "../../components/dynamic/observer";

class AdvantagesScrollSlider {
  constructor(selector) {
    this.container = selector;
    this.slides = this.container.querySelectorAll('[data-advantages-scroll-slide]');

    this.bind();
  }

  bind() {
    window.addEventListener('scroll', () => {
      this.slides.forEach((slide) => {
        if (slide.offsetHeight < window.innerHeight && window.scrollY > slide.offsetTop) {
          slide.style.position = 'sticky';
          slide.style.top = '0';
        } else if ((window.scrollY + window.innerHeight) > (slide.offsetTop + slide.offsetHeight)) {
          const top = (slide.offsetHeight - window.innerHeight) * -1;
          slide.style.position = 'sticky';
          slide.style.top = `${top}px`;
        }
      });
    });
  }
}
onDomReady(() => {
  const el = document.querySelector('[data-advantages-scroll-slider]');
  if (el) {
    new AdvantagesScrollSlider(el);
  }
})