// Новее лучше цельнее

// Копирование текста из блока
// Нажатие на элементы
// Наведение на элементы
// Просмотр блока
// Взаимодействие с формой (inputs)
// Успешная отправка формы

class GoalManager {
  constructor() {
    this.systemsNames = [
      'google',
      'yandex',
      // 'facebook',
      'vk'
    ];
    this.middlewares = [];

    // Обойти все теги носкрипт и найти в них совпадение по https://top-fwz1.mail.ru/counter?id=3310730
    // И запомнить этот идентификатор
    this.vkCounters = [];
    document.querySelectorAll('noscript').forEach((counter) => {
      const matches = counter.outerHTML.match(/https:\/\/top-fwz1.mail.ru\/counter\?id=(\d+)/)
      if (matches) {
        this.vkCounters.push(parseInt(matches[1], 10));
      }
    });
  }

  /**
   * middleware это колбэк, который отрабатывает при всех событиях, возникающих с элементами
   * middleware возвращает bool значение
   * если middleware возвращает false, то обработка прерывается
   *
   * stopped = middleware(element, event)
   *
   * @param middleware
   */
  registerMiddleware(middleware) {
    this.middlewares.push(middleware);
  }

  proceedGoal(systemName, goalData) {
    switch (systemName) {
      case 'yandex':
        return this.proceedYandexGoal(goalData);
      case 'google':
        return this.proceedGoogleGoal(goalData);
      // case 'facebook':
      //   return this.proceedFacebookGoal(goalData);
      case 'vk':
        return this.proceedVkGoal(goalData);
      default:
        console.error(`GoalManager: Incorrect system name ${systemName}`);
    }
    return null;
  }

  proceedYandexGoal(goalName) {
    if (window.Ya && window.Ya._metrika) {
      window.Ya._metrika.counter.reachGoal(goalName);
    } else {
      console.error('GoalManager: Try send yandex event, but yandex counter not found');
    }
  }

  proceedFacebookGoal(goalName) {
    if (window.fbq) {
      window.fbq('track', goalName);
    } else {
      console.error('GoalManager: Try send facebook event, but facebook counter not found');
    }
  }

  proceedGoogleGoal(goalData) {
    const goalElements = goalData.split('#');
    if (!goalElements[0] || !goalElements[1]) {
      console.error('GoalManager: Incorrect goal data for google, please read documentation. Format google goal: event_category#action');
    }
    const [eventCategory, action, label, value] = goalElements;
    const options = {
      event_category: eventCategory,
    };
    if (label) {
      options.event_label = label;
    }
    if (value) {
      options.value = value;
    }
    if (window.gtag) {
      window.gtag('event', action, options);
    } else {
      console.error('GoalManager: Try send google event, but google counter not found');
    }
  }

  proceedVkGoal(goalData) {
    let goalName = goalData;
    let goalWeight = 0;
    if (goalData.includes('#')) {
      const splitted = goalData.split('#');
      goalName = splitted[0];
      goalWeight = parseInt(splitted[1], 10);
    }

    let _tmr = window._tmr || (window._tmr = [])
    if (this.vkCounters && _tmr) {
      this.vkCounters.forEach((counterId) => {
        const goalInfo = { type: 'reachGoal', id: counterId, goal: goalName};
        if (goalWeight) {
          goalInfo['value'] = goalWeight;
        }
        _tmr.push(goalInfo)
      });
    } else {
      console.log('GoalManager: Try send vk event, but vk counter not found');
    }
  }

  proceedGoalsForElement(element, event) {
    if (!element) {
      console.error(`GoalManager: Incorrect element passed for event ${event}`);
      return;
    }
    let stopped = false;
    this.middlewares.forEach((middleware) => {
      if (stopped) {
        return;
      }
      stopped = middleware(element, event);
    });
    if (stopped) {
      return;
    }
    this.systemsNames.forEach((systemName) => {
      const property = this.buildDatasetProperty(systemName, event);
      const goalData = element.dataset[property];
      if (goalData) {
        this.proceedGoal(systemName, goalData);
      }
    });
  }

  /**
   * 'yandex', 'click' => 'goalYandexClick'
   * @param systemName
   * @param event
   * @returns {string}
   */
  buildDatasetProperty(systemName, event) {
    const systemNameUp = GoalManager.capitalizeFirstLetter(systemName);
    const eventUp = GoalManager.capitalizeFirstLetter(event);
    return `goal${systemNameUp}${eventUp}`;
  }

  /**
   * 'click' => ['data-goal-yandex-click', 'data-goal-google-click', ...]
   * @param event string
   */
  buildDatasetAttributes(event) {
    return this.systemsNames.map(systemName => `data-goal-${systemName}-${event}`);
  }

  /**
   * 'click' => '[data-goal-yandex-click], [data-goal-google-click]'
   * @param event
   * @returns {string}
   */
  buildDatasetSelector(event) {
    const selectors = this.buildDatasetAttributes(event).map(attr => `[${attr}]`);
    return selectors.join(',');
  }

  static dashesToCamelCase(str) {
    return str.replace(/-([a-z])/g, (m, w) => w.toUpperCase());
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

window.goalManager = new GoalManager();

// А теперь песни и пляски

// Отслеживаем клики
// data-goal-yandex-click, data-goal-google-click, ...
document.body.addEventListener('click', (e) => {
  const element = e.target.closest(window.goalManager.buildDatasetSelector('click'));
  if (element) {
    window.goalManager.proceedGoalsForElement(element, 'click');
  }
});

// Отслеживаем наведения
// data-goal-yandex-hover, data-goal-google-hover, ...
document.body.addEventListener('mouseover', (e) => {
  const element = e.target.closest(window.goalManager.buildDatasetSelector('hover'));
  if (element) {
    window.goalManager.proceedGoalsForElement(element, 'hover');
  }
});

// Отслеживаем копирование
// data-goal-yandex-copy, data-goal-google-copy, ...
document.body.addEventListener('copy', (e) => {
  const element = e.target.closest(window.goalManager.buildDatasetSelector('copy'));
  if (element) {
    window.goalManager.proceedGoalsForElement(element, 'copy');
  }
});

// Отслеживаем что пользователь увидел элемент
// data-goal-yandex-see, data-goal-google-see, ...
const seeGoalProceed = () => {
  function getTopOfElement(element) {
    const now = new Date().getTime();
    const lastUpdate = element.dataset.documentOffsetTopTime ? element.dataset.documentOffsetTopTime : 0;
    if (now - lastUpdate > 800) {
      element.dataset.documentOffsetTop = window.pageYOffset + element.getBoundingClientRect().top;
      element.dataset.documentOffsetTopTime = now;
    }
    return parseInt(element.dataset.documentOffsetTop, 10);
  }

  document.querySelectorAll(
    window.goalManager.buildDatasetSelector('see'),
  ).forEach((element) => {
    const startBorder = window.pageYOffset + window.innerHeight - window.innerHeight * 0.5;
    if (startBorder >= getTopOfElement(element) && !element.dataset.seeGoalSent) {
      window.goalManager.proceedGoalsForElement(element, 'see');
      element.dataset.seeGoalSent = 'true';
    }
  });
};
window.addEventListener('scroll', seeGoalProceed);
seeGoalProceed();

// Отслеживаем взаимодействие с формой
// data-goal-yandex-input, data-goal-google-input, ...
const inputEventProceed = (e) => {
  const element = e.target.closest(window.goalManager.buildDatasetSelector('input'));
  if (element) {
    window.goalManager.proceedGoalsForElement(element, 'input');
  }
};
document.body.addEventListener('input', inputEventProceed);
document.body.addEventListener('change', inputEventProceed);

// Отправка форм (модальные и инлайновые)
// data-goal-yandex-submit, data-goal-google-submit, ...
// Для модальных - вешаем на ссылку, для инлайновых на form
document.addEventListener('modal-form:success', (e) => {
  if (e.element) {
    window.goalManager.proceedGoalsForElement(e.element, 'submit');
  }
});
document.addEventListener('ajax-form:success', (e) => {
  if (e.detail.form) {
    window.goalManager.proceedGoalsForElement(e.detail.form, 'submit');
  }
});

document.addEventListener('favAdded', (e) => {
  let favLink = document.querySelector('[data-global-fav-link]');
  let favGoal = favLink.dataset.favGoal;
  if (favGoal) {
    window.goalManager.proceedYandexGoal(favGoal);
    window.goalManager.proceedVkGoal(favGoal);
  }
})

// Отправка submit всех форм в YM и Vk
window.goalManager.registerMiddleware((element, event) => {
  if (event === 'submit') {
    const goalName = document.body.dataset.allLead

    if (goalName) {
      window.goalManager.proceedVkGoal(goalName);
      window.goalManager.proceedYandexGoal(goalName);
    }
  }
});
