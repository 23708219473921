import {onDomChanges, onDomReady} from "../../../static/components/dynamic/observer";

function bindClick(linkElement) {
  linkElement.addEventListener('click', async (e) => {
    e.preventDefault();
    if (linkElement.dataset.loading === 'true') {
      return;
    }
    linkElement.dataset.loading = 'true';
    const response = await fetch(linkElement.getAttribute('href'));
    const json = await response.json();

    if (json.message) {
      alert(json.message);
    }

    if ('in' in json) {
      if (json.in) {
        linkElement.dataset.inFav = 'true';

        const event = new CustomEvent('favoriteToggle', {
          detail: {
            add: true,
          },
        });
        document.dispatchEvent(event);
        document.dispatchEvent(new Event('favAdded'));
      } else {
        linkElement.dataset.inFav = 'false';

        const event = new CustomEvent('favoriteToggle', {
          detail: {
            add: false,
          },
        });
        document.dispatchEvent(event);
      }
    }

    linkElement.dataset.loading = 'false';
  });
}

function initElements() {
  document.querySelectorAll('[data-global-fav-link]').forEach((linkElement) => {
    if (linkElement.dataset.initialized) {
      return;
    }
    linkElement.dataset.initialized = true;
    bindClick(linkElement);
  });
}

function refreshUserIfNeeded() {
  const refreshLink = document.querySelector('[data-global-fav-refresh-user]');
  if (!refreshLink) {
    return;
  }
  fetch(refreshLink.getAttribute('href'));
}

onDomReady(() => {
  initElements();
  refreshUserIfNeeded();
});

onDomChanges(() => {
  initElements();
});