import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Swiper} from "swiper";

onDomReady(() => {
  const elements = document.querySelectorAll('[data-about-slider]');
  elements.forEach((element) => {
    const slider = new Swiper(element, {
      modules: [Navigation, Pagination],
      pagination: {
        el: element.querySelector('[data-about-slider-pagination]'),
        type: 'bullets',
      },
      navigation: {
        prevEl: element.querySelector('[data-about-slider-left]'),
        nextEl: element.querySelector('[data-about-slider-right]')
      }
    })

    element.querySelector('[data-about-slider-desk-left]').addEventListener('click', () => {
      slider.slidePrev();
    })
    element.querySelector('[data-about-slider-desk-right]').addEventListener('click', () => {
      slider.slideNext();
    })
  });
})