<template>
  <div class="form-field" :class="blockClasses">
    <label v-if="field.label" :class="elementClasses('label')">
      {{ field.label }}
    </label>

    <div data-vue-custom-sort
         class="custom-select custom-select_sort"
         :class="{_open: isOpen}"
    >
      <div class="custom-select__inner">
        <div class="custom-select__field-wrapper custom-select__field-wrapper_sort">
          <input type="text"
                 class="custom-select__field custom-select__field_sort text__body"
                 :value="selectedLabels"
                 @click="openToggle"
                 readonly
                 v-if="hasCheckedValues"
          >
          <div class="custom-select__arrow-icon custom-select__arrow-icon_absolute custom-select__arrow-icon_sort">
            <Arrow/>
          </div>
        </div>

        <div class="custom-select__content-wrap custom-select__content-wrap_sort">
          <ul class="custom-select__list">
            <li v-if="!field.choices.length">
              <div class="custom-select__no-choices text__grey03 text__small">
                Нет результатов
              </div>
            </li>
            <li v-for="(choice, index) in choices" class="custom-select__item">
              <input
                  type="radio"
                  :id="id(choice, index)"
                  :value="choice.key"
                  class="custom-select__input"
                  v-model="valueItem.value"
              >
              <label :for="id(choice, index)" class="custom-select__label">
                <span class="custom-select__choice-icon">
                  <CheckMarkBlack/>
                </span>
                <span class="custom-select__choice-name text__grey03 text__small">
                  {{ choice.label }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fieldMixin} from "./fieldMixin";
import Arrow from "../../components/icons/Arrow.vue";
import CheckMarkBlack from "../../components/icons/CheckMarkBlack.vue";

export default {
  name: "SelectMultiField",
  components: {
    CheckMarkBlack,
    Arrow
  },
  mixins: [
    fieldMixin
  ],
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    hasCheckedValues() {
      return this.valueItem.value ? this.valueItem.value.length : false;
    },
    choices() {
      let choices = this.field.choices;
      const orderedChoices = this.field.data.orderedChoices;
      if (orderedChoices && orderedChoices.length) {
        choices.sort((a, b) => {
          if (!orderedChoices.includes(a.key) || !orderedChoices.includes(b.key)) {
            return;
          }
          return orderedChoices.indexOf(a.key) - orderedChoices.indexOf(b.key);
        });
      }
      return choices;
    },
    selectedLabels() {
      const values = this.valueItem.value; // [15, 16]
      const choices = this.choices; // [{key: 16, label: "Наименование"}]
      const labels = [];

      for (let i = 0; i < choices.length; i++) {
        if (values === (choices[i].key)) {
          labels.push(choices[i].label);
        }
      }
      return labels;
    }
  },
  methods: {
    checkValue() {
      if (!this.valueItem.value) {
        this.valueItem.value = [];
      }
    },
    id(choice, index) {
      index = index | 0;
      return `${this.field.key}_${choice.key}_${index}`;
    },
    openToggle(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
    },
  },
  mounted() {
    this.checkValue();

    const el = this.$el.querySelector('[data-vue-custom-sort]');
    document.addEventListener('click', (e) => {
      if (this.isOpen && !(el === e.target || el.contains(e.target))) {
        this.isOpen = false;
      }
    });
  },
  beforeUpdate() {
    this.checkValue();
  }
}
</script>

<style lang="scss">

</style>