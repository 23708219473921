import {onDomReady} from "../../components/dynamic/observer";

class AdvantageBricks {
  constructor(selector, settings) {
    this.container = selector;
    this.gridSizes = settings.gridSizes;
    this.schemas = settings.schemas;
    this.items = Array.from(this.container.querySelectorAll('[data-advantage-brick]'));

    this.init();
    this.eventListeners();
  }

  init() {
    if (window.innerWidth >= 1200) {
      // разбить массив с элементами на подмассивы по 7
      const subarrays = this.splitItems();
      subarrays.forEach((subArray) => {
        // получаем номер схемы для текущего подмассива
        const schemaNumber = subArray.length - 1;
        subArray.forEach((item, index) => {
          // получаем название размера для текущего элемента
          const gridSizeName = this.schemas[schemaNumber][index];
          item.style.gridColumn = `span ${this.gridSizes[gridSizeName]}`;
        });
      });
    } else {
      this.items.forEach((item) => {
        item.style.gridColumn = 'span 1';
      })
    }
  }

  splitItems() {
    const size = 7; //размер подмассива
    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(this.items.length / size); i++) {
      subarray[i] = this.items.slice((i * size), (i * size) + size);
    }
    return subarray;
  }

  eventListeners() {
    window.addEventListener('resize', () => {
      this.init();
    })
  }
}

onDomReady(() => {
  const el = document.querySelector('[data-advantage-bricks]');
  if (el) {
    new AdvantageBricks(el, {
      // размеры элементов в сетке grid
      gridSizes: {
        xl: 12,
        l: 8,
        m: 6,
        s: 4
      },
      // cхемы "как выстраиватся" при разном количестве элементов
      schemas: [
        ['xl'],
        ['s', 'l'],
        ['s', 's', 's'],
        ['s', 'l', 'l', 's'],
        ['s', 'l', 's', 's', 's'],
        ['s', 'l', 'm', 'm', 'l', 's'],
        ['s', 'l', 's', 's', 's', 'm', 'm'],
      ]
    });
  }
});