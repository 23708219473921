// Настройки фильтров под проект
import { FilterManagerPool } from "../../vue/filter/manager";
import { debounce } from "lodash";
import {onDomReady} from "../../components/dynamic/observer";

// Кнопка отправки
const filterSubmits = document.querySelectorAll('[data-filter-submit]');
filterSubmits.forEach((filterSubmit) => {
  filterSubmit.addEventListener('click', () => {
    const manager = FilterManagerPool.getManager(filterSubmit.dataset.filterKey);
    manager.submit();
  });
});

// Отправлять фильтр при любых изменениях

onDomReady(() => {
  document.addEventListener('filter:values-changed', debounce((e) => {
    const { filterManager } = e.detail;
    filterManager.submit();
  }, 300));
});

