import { onDomChanges } from "../../components/dynamic/observer";
import { Genplan } from './genplan';
import Swiper, {Navigation} from "swiper";
import Jax from "../../components/jax/jax";

class GenplanFloorModal {
  constructor(selector) {
    this.container = selector;
    this.container.dataset.initialized = 'true';
    this.genplanContainer = this.container.querySelector('[data-gen-floor-container]');
    this.sliderContainerEl = this.container.querySelector('[data-gen-floor-slider-container]');

    this.currentFloorItem = this.sliderContainerEl.querySelector('[data-current-floor]');
    this.currentFloorKey = this.currentFloorItem.dataset.key;
    this.currentFloorId = parseInt(this.currentFloorItem.querySelector('a').dataset.floorId, 10);

    this.initedGenplanIds = [];

    this.initSliders();
    this.init();
    this.eventListeners();
  }

  initSliders() {
    this.initMainSlider(parseInt(this.currentFloorKey, 10));
    this.initHeadingSlider(parseInt(this.currentFloorKey, 10));
  }
  initMainSlider(initFloor) {
    if (!this.sliderContainerEl) {
      return;
    }

    const sliderEl = this.sliderContainerEl.querySelector('[data-floor-number-slider]');
    const sliderLeft = this.sliderContainerEl.querySelector('[data-floor-number-top]');
    const sliderRight = this.sliderContainerEl.querySelector('[data-floor-number-bottom]');

    this.slider = new Swiper(sliderEl, {
      modules: [Navigation],
      direction: 'vertical',
      centeredSlides: true,
      // loop: true,
      speed: 1000,
      slideToClickedSlide: true,
      breakpoints: {
        375: {
          slidesPerView: 1,
          navigation: {
            prevEl: sliderRight,
            nextEl: sliderLeft,
          },
        },
        1200: {
          slidesPerView: 7,
          navigation: {
            prevEl: sliderLeft,
            nextEl: sliderRight,
          },
        }
      },
      on: {
        init: function (swiper) {
          swiper.slideTo(initFloor);
        },
      },
    });
  }

  initHeadingSlider(initFloor) {
    const headingSliderEl = this.container.querySelector('[data-floor-heading-slider]');
    if (!headingSliderEl) {
      return;
    }

    this.headingSlider = new Swiper(headingSliderEl, {
      direction: 'vertical',
      slidesPerView: 1,
      speed: 1000,
      on: {
        init: function (swiper) {
          swiper.slideTo(initFloor);
        },
      },
    });
  }

  init() {
    if (!this.initedGenplanIds.includes(this.currentFloorId)) {
      this.getGenplanData(this.currentFloorId);
      this.initGenplan();
      this.initedGenplanIds.push(this.currentFloorId);
    } else {
      this.genplanContainer.querySelectorAll('[data-floor-id]').forEach((item) => {
        item.classList.remove('_visible');

        if (parseInt(item.dataset.floorId, 10) === this.currentFloorId) {
          item.classList.add('_visible');
        }
      });
    }
  }

  getGenplanData(floorId) {
    let jax = new Jax(`/realty/floor_genplan/${floorId}`);
    jax.send().then((html) => {
      let genplanItem = document.createElement('div');
      genplanItem.classList.add('floor__genplan');
      genplanItem.classList.add('_visible');
      genplanItem.dataset.floorId = floorId;
      genplanItem.innerHTML = html;

      this.genplanContainer.appendChild(genplanItem);
    })
  }

  initGenplan() {
    setTimeout(() => {
      const genplanEl = this.container.querySelector('[data-gen]:not([data-initialized="true"])')
      if (!genplanEl) {
        return;
      }

      const genplanItem =  new Genplan(genplanEl, {
        // Отступ точки от края видимой области
        poiGap: 0,
        // Отступ балуна от края видимой области
        balloonGap: 0,
        // Проверка на то, может ли пользователь скроллить включается ниже данного значения ширины
        // экрана
        scrollableToWidth: 1200,
        // Высота мини-карты
        minimapHeight: 50,
        // contain / cover - аналогию, я думаю, вы поняли
        size: genplanEl.dataset.size ? genplanEl.dataset.size : 'cover',
        // Важные точки интереса (объекты) для скролл-режима
        // они определяют какую часть генплана показывать сначала в мобильном режиме
        // проще говоря на сколько скроллить изначально в мобильном (скроллящемся) режиме
        scrollableImportantObjectTypes: ['important'],
        type: 'genplan-floor',
      });
      genplanEl.dataset.initialized = 'true';

      if (window.location.hash) {
        document.dispatchEvent(new CustomEvent('request-openen-genplan-scene', {
          detail: {
            sceneName: window.location.hash.substring(1),
          },
        }));
      }
    }, 1000);
  }

  eventListeners() {
    this.slider.on('activeIndexChange', (e) => {
      this.genplanContainer.dataset.initialized = 'true';

      const activeIndex = this.slider.activeIndex;
      const activeSlideLink = this.slider.slides[activeIndex].querySelector('a');
      this.currentFloorId = parseInt(activeSlideLink.dataset.floorId, 10);
      this.init();

      if (this.headingSlider) {
        this.headingSlider.slideTo(activeIndex);
      }
    });
  }
}

onDomChanges(() => {
  const el = document.querySelector('[data-gen-floor-modal]:not([data-initialized="true"])');
  if (el) {
    new GenplanFloorModal(el);
  }
});