<template>
    <div :class="blockClasses">
      <div class="plannings-filter__buttons-wrap">
        <a href="#flats" class="plannings-filter__filter-button button" data-scroll-link data-offset="-55">
          Найти квартиры
        </a>

        <a href="/" class="plannings-filter__drop-filter">
            <span class="plannings-filter__drop-icon">
                <DropFilter/>
            </span>
            <span class="plannings-filter__drop-filter-text text__black-text">
                Сбросить фильтр
            </span>
        </a>
      </div>
    </div>
</template>

<script>
  import { fieldMixin } from "./fieldMixin";
  import DropFilter from "../../components/icons/DropFilter.vue";

  export default {
    name: "PlanningsActivityField",
    components: {
      DropFilter,
    },
    mixins: [
      fieldMixin
    ]
  }
</script>

<style lang="scss">

</style>