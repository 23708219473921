function cleanFormErrors(form, formname) {
  form.querySelectorAll('[data-errors]').forEach((errors) => {
    errors.innerHTML = '';
  });
  form.querySelectorAll('.form-field').forEach((field) => {
    field.classList.remove('_errors');
  });
}

function makeListErrors(namespace, errors) {
  const result = {};
  Object.keys(errors).forEach((name) => {
    const joined = `${namespace}[${name}]`;
    if (errors[name] instanceof Array) {
      result[joined] = errors[name];
    } else {
      const innerResult = makeListErrors(joined, errors[name]);
      Object.keys(innerResult).forEach((innerName) => {
        result[innerName] = innerResult[innerName];
      });
    }
  });
  return result;
}

function showErrors(form, formname, errors) {
  const errorsList = makeListErrors(formname, errors);
  Object.keys(errorsList).forEach((name) => {
    const field = form.querySelector(`[data-name='${name}']`);
    field.closest('.form-field').classList.add('_errors');

    const errors = form.querySelector(`[data-errors][data-name='${name}']`);
    errorsList[name].forEach((error) => {
      errors.style.display = '';
      let listItem = document.createElement('li');
      listItem.innerText = error;
      errors.appendChild(listItem)
    });
  });
}

export {
  cleanFormErrors,
  makeListErrors,
  showErrors
}