<template>
    <div :class="blockClasses">
        Default field
    </div>
</template>

<script>
import { fieldMixin } from "./fieldMixin";

export default {
  name: "DefaultField",
  mixins: [
    fieldMixin
  ]
}
</script>

<style lang="scss">

</style>