export default class Action {
  constructor(name, duration, delay, onStart, onEnd, easingIn, easingOut) {
    this._name = name ? name : 'action';
    this._duration = duration ? duration : 32;
    this._delay = delay ? delay : 0;
    this._onStart = onStart ? onStart : () => {};
    this._onEnd = onEnd ? onEnd : () => {};
    this._easingIn = easingIn ? easingIn : (x) => {return x;}
    this._easingOut = easingOut ? easingOut : (x) => {return x;}
  }

  get duration() {
    return this._duration;
  }

  get delay() {
    return this._delay;
  }

  get onStart() {
    return this._onStart;
  }

  get onEnd() {
    return this._onEnd;
  }

  get name() {
    return this._name;
  }

  get easingIn() {
    return this._easingIn;
  }

  get easingOut() {
    return this._easingOut;
  }
}
