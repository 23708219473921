import { ScrollSlider } from './scrollSlider/ScrollSlider';

window.addEventListener('load', () => {
    initSlider();
})

// Example of scrollSlider
function initSlider()
{
    if (window.innerWidth > 1199 && document.querySelector('[data-scroll-slider]')) {
        document.querySelector('[data-scroll-slider]').classList.add('_scroller');
        new ScrollSlider(document.querySelector('[data-scroll-slider]'));
    }
}