import {onDomReady} from "../../components/dynamic/observer";
import Modal from "../../components/modal/modal";
import Jax from "../../components/jax/jax";

class ModalShareHandler {
  type = undefined;
  layoutId = undefined;
  apartmentId = undefined;
  outerId = undefined;

  constructor() {
    this.routes = {
      layout: {
        check: '/share/layout/',
        modal: '/realty/layout_modal/'
      },
      apartment: {
        check: '/share/apartment/',
        modal: '/realty/apartment_modal/'
      },
      outer: {
        check: '/share/outer/',
        modal: '/realty/outer_modal/'
      },
    }

    this.init();
  }

  init() {
    this.handleQueryString();
    this.defineType();
    this.createLinkEl();
  }

  handleQueryString() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.layoutId = urlParams.get('layoutId');
    this.apartmentId = urlParams.get('apartmentId');
    this.outerId = urlParams.get('appartModal');
  }

  defineType() {
    if (this.layoutId) {
      this.type = 'layout';
    } else if (this.apartmentId) {
      this.type = 'apartment';
    } else if (this.outerId) {
      this.type = 'outer';
    }
  }

  createLinkEl() {
    let checkUrl = null;
    let modalUrl = null;

    if (this.type === 'layout') {
      modalUrl = `${this.routes[this.type].modal}${this.layoutId}`;
      checkUrl = `${this.routes[this.type].check}${this.layoutId}`;
    } else if (this.type === 'apartment') {
      modalUrl = `${this.routes[this.type].modal}${this.apartmentId}`;
      checkUrl = `${this.routes[this.type].check}${this.apartmentId}`;
    } else if (this.type === 'outer') {
      modalUrl = `${this.routes[this.type].modal}${this.outerId}`;
      checkUrl = `${this.routes[this.type].check}${this.outerId}`;
    } else {
      return;
    }

    let jax = new Jax(checkUrl);
    jax.send()
      .then(() => {
      this.link = document.createElement('a');
      this.link.setAttribute('href', modalUrl);
      this.link.setAttribute('data-modal', '');
      this.link.setAttribute('data-modal-theme', '_layout-modal');

      this.createModal();
    }).catch(() => {
      window.location.href = '/_error/404';
    });
  }

  createModal() {
    if (!this.link) {
      return;
    }

    this.modal = new Modal(this.link, {
      theme: this.link.dataset.modalTheme ?? 'default',
      animation: {
        classIn: '_open',
        classOut: '_close',
        timeoutIn: 16,
        timeoutOut: 16,
      },
      closerText: '',
      onAfterOpen: function () {
      },
      onBeforeClose: function () {
      },
      onFormSuccess: function () {
        const submitEvent = new Event('modal-form:success');
        submitEvent.element = this.link;
        document.dispatchEvent(submitEvent);
      },
    });
  }
}

onDomReady(() => {
  new ModalShareHandler();
})