import {SceneObject} from "./object";
/**
 * Сцена (картинка + объекты на картинке, с которыми можно взаимодействовать)
 */
export class Scene {
  constructor(sceneElement, sceneModalsElement, settings) {
    this.settings = settings;
    this.initialized = false;
    this.scrollPositionInitialized = false;
    this.name = sceneElement.dataset.genScene;

    // Где проходят границы видимой области относительно левого и верхнего краёв изображения (top, left, bottom, right)
    // Плюсом - размеры контейнера и изображения
    this.renderedFrame = null;

    if (!this.name) {
      console.error('Could not get scene name from [data-gen-scene] ' +
        'attribute, please provide correct scene name with data-attribute');
    }
    // Элементы
    this.sceneElement = sceneElement;
    this.sceneModalsElement = sceneModalsElement;
    // Контейнер общий - для скролла
    this.containerElement = document.createElement('div');
    // Контейнер со слоями
    this.layersElement = document.createElement('div');
    // Слои
    this.layersElements = {};
    // Задник с блюром
    this.backgroundElement = document.createElement('div');
    // Слой с элментами интерфейса (пользовательскими элементами)
    this.interfaceElement = sceneElement.querySelector('[data-gen-scene-interface]');
    if (!this.interfaceElement) {
      this.interfaceElement = document.createElement('div');
    }

    // SVG-слои (2шт)
    this.substrateSvgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.polygonSvgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    // Мини-карта (для скролла)
    this.minimapContainerElement = document.createElement('div');
    this.minimapHandleElement = document.createElement('div');
    this.minimapBarElement = document.createElement('div');

    // Объекты
    this.objects = [];

    this.sceneElement.classList.add('genplan__scene');

    this.init();
  }

  getName() {
    return this.name;
  }

  isInitialized() {
    return this.initialized;
  }

  init() {
    // Добавляем контейнер
    this.initContainer();

    // Перестраиваем структуру под наши нужды
    // Слои по очереди от самых нижних до самых верхних:
    this.initLayers();

    // Инициализируем изображение для фона
    this.initBackground();

    // Инициализируем само изображение
    this.initImage();

    // Инициализируем изображение c зажженным светом
    this.initLightOnImage();

    // Инициализируем SVG-слои
    this.initSvgLayers();

    // Инициализируем номера этажей
    this.initFloorNumbers();

    // Инициализируем объекты
    this.initObjects();

    // Инициализируем мини-карту
    this.initMinimap();

    // Инициализируем слой с балунами
    this.initSceneModals();

    this.initListeners();
  }

  initContainer () {
    this.containerElement.classList.add('genplan__scene-container');
    this.sceneElement.appendChild(this.containerElement);
  }

  initLayers() {
    // background - самый нижний слой, для отрисовки задника с блюром
    // substrate-layer - слой с полигонами под изображением (когда изображение в png и нам нужно подсвечивать ПОД ним)
    // image-layer - слой с изображением непосредственно
    // light-on-image-layer - слой с изображением с зажженным светом
    // polygon-layer - слой с полигонами над изображением
    // interactive-layer - слой с плейсмарками и балунами

    this.containerElement.appendChild(this.layersElement);
    this.containerElement.appendChild(this.interfaceElement);
    this.containerElement.appendChild(this.backgroundElement);
    this.layersElement.classList.add('genplan__layers');

    this.layersElements = {
      substrate: document.createElement("div"),
      image: document.createElement("div"),
      lightOnImage: document.createElement("div"),
      polygon: document.createElement("div"),
      clipPath: document.createElement("div"),
      interactive: document.createElement("div"),
      floorNumbers: document.createElement("div"),
    };

    this.layersElement.append(this.layersElements.substrate);
    this.layersElement.append(this.layersElements.image);
    this.layersElement.append(this.layersElements.lightOnImage);
    this.layersElement.append(this.layersElements.polygon);
    this.layersElement.append(this.layersElements.clipPath);
    this.layersElement.append(this.layersElements.interactive);

    if (this.isMobileBuildingGenplan()) {
      this.layersElement.append(this.layersElements.floorNumbers);
      this.layersElements.floorNumbers.classList.add('genplan__layer-floor-numbers');
    }

    // this.backgroundElement.classList.add('genplan__background');
    let type = 'default';
    if (this.settings.type) {
      type = this.settings.type;
    }
    this.interfaceElement.classList.add('genplan__interface');
    this.layersElements.substrate.classList.add('genplan__layer-substrate');
    this.layersElements.image.classList.add('genplan__layer-image');
    this.layersElements.lightOnImage.classList.add('genplan__layer-light-on-image');
    this.layersElements.polygon.classList.add('genplan__layer-polygon');
    this.layersElements.clipPath.classList.add('genplan__layer-clip-path');
    this.layersElements.polygon.classList.add(type);
    this.layersElements.interactive.classList.add('genplan__layer-interactive');
  }

  initBackground() {
    // Прописываем изображение для фона
    const imgElement = this.sceneElement.querySelector('[data-gen-picture] img');
    let counter = 5;
    let initedBgInterval = setInterval(() => {
      if (imgElement.currentSrc) {
        // this.backgroundElement.style['background-image'] = `url('${imgElement.currentSrc}')`;
        counter -= 1;
      }
      if (counter === 0) {
        clearInterval(initedBgInterval);
      }
    }, 300);
  }

  initImage() {
    // Переносим изображение на нужный слой
    const pictureElement = this.sceneElement.querySelector('[data-gen-picture]');
    if (pictureElement) {
      this.layersElements.image.appendChild(
        pictureElement,
      );
    } else {
      console.error(`Could not find image in scene ${  this.name}`);
    }
  }

  initLightOnImage() {
    // Переносим изображение на нужный слой
    const pictureElement = this.sceneElement.querySelector('[data-gen-light-on-picture]');
    if (pictureElement) {
      this.layersElements.lightOnImage.appendChild(
        pictureElement,
      );
    } else {
      console.error(`Could not find image in scene ${  this.name}`);
    }
  }

  initSvgLayers() {
    // Ставим все необходимые параметры для SVG

    this.substrateSvgElement.setAttributeNS(null, 'viewBox', '0 0 100 100');
    this.polygonSvgElement.setAttributeNS(null, 'viewBox', '0 0 100 100');

    this.substrateSvgElement.setAttributeNS(null, 'preserveAspectRatio', 'none');
    this.polygonSvgElement.setAttributeNS(null, 'preserveAspectRatio', 'none');

    this.layersElements.substrate.appendChild(this.substrateSvgElement);
    this.layersElements.polygon.appendChild(this.polygonSvgElement);
  }

  initObjects() {
    this.sceneElement.querySelectorAll('[data-gen-object]').forEach((objectElement) => {
      // Объект сцены
      const object = new SceneObject(
        objectElement,
        this.sceneModalsElement,
        this.settings,
      );

      // Получаем кусочки SVG
      const svgPathPolygonElement = object.getSvgPathPolygonElement();
      if (svgPathPolygonElement) {
        this.polygonSvgElement.appendChild(svgPathPolygonElement);
      }
      const svgPathSubstrateElement = object.getSvgPathSubstrateElement();
      if (svgPathSubstrateElement) {
        this.substrateSvgElement.appendChild(svgPathSubstrateElement);
      }

      // Получаем контейнер (в нем находятся плейсмарк и балун)
      const container = object.getContainerElement();
      this.layersElements.interactive.appendChild(container);

      this.objects.push(object);
    });
  }

  initMinimap() {
    this.minimapContainerElement.classList.add('genplan__minimap');
    this.minimapBarElement.classList.add('genplan__minimap-bar');
    this.minimapHandleElement.classList.add('genplan__minimap-handle');

    this.minimapBarElement.appendChild(this.minimapHandleElement);
    this.minimapContainerElement.append(this.minimapBarElement);

    this.containerElement.addEventListener('scroll', (e) => {
      this.onSceneScroll();
    });

    this.sceneElement.appendChild(this.minimapContainerElement);
  }

  initSceneModals() {
    this.sceneModalsElement.addEventListener('click', (e) => {
      if (e.target === this.sceneModalsElement) {
        this.objects.forEach((object) => {
          this.sceneModalsElement.classList.remove('_opened');
          object.unhover();
        });
      }
    });
    this.objects.forEach((object) => {
      object.onBalloonCloseCallback = () => {
        this.sceneModalsElement.classList.remove('_opened');
      };
    });
  }

  onSceneScroll() {
    if (!this.renderedFrame) {
      return;
    }

    const minimapScale = this.settings.minimapHeight / this.renderedFrame.imageHeight;
    const minimapHeight = this.settings.minimapHeight;
    const minimapWidth = minimapScale * this.renderedFrame.imageWidth;
    const barWidth = minimapScale * this.renderedFrame.containerWidth;
    const barWidthPercent = barWidth / minimapWidth * 100;
    const scrollPercent = this.containerElement.scrollLeft / this.renderedFrame.imageWidth * 100;

    this.minimapContainerElement.style.width = minimapWidth + 'px';
    this.minimapHandleElement.style.width = barWidthPercent + '%';
    this.minimapHandleElement.style.left = scrollPercent + '%';

    if (this.isMobileBuildingGenplan()) {
      const barHeight = minimapScale * this.renderedFrame.containerHeight;
      const barHeightPercent = barHeight / minimapHeight * 100;
      const scrollTopPercent = this.containerElement.scrollTop / this.renderedFrame.imageHeight * 100;
      this.minimapContainerElement.style.height = minimapHeight + 'px';
      this.minimapHandleElement.style.height = barHeightPercent + '%';
      this.minimapHandleElement.style.top = scrollTopPercent + '%';
    }
  }


  initScrollPosition() {
    if (this.scrollPositionInitialized) {
      return;
    }

    // Если в настройках передан центр в процентах - юзаем его
    if (this.settings.center) {
      const centerPx = this.renderedFrame.imageWidth * this.settings.center / 100;
      this.containerElement.scrollLeft = centerPx - this.renderedFrame.containerWidth / 2;
      this.containerElement.scrollTop = this.renderedFrame.imageHeight;
    } else {
      // Если есть - ищем центр только для самых важных точек
      // Например, инфраструктура менее нам интересна нежели дома, в которых мы продаем квартиры
      // Поэтому ставим центр в важное место
      const importantTypes = this.settings.scrollableImportantObjectTypes
          ? this.settings.scrollableImportantObjectTypes : null;
      const bordersOfPoi = this.getBordersOfPoi(importantTypes);

      let center = 50;
      if (bordersOfPoi) {
        center = (bordersOfPoi.left + bordersOfPoi.right) / 2;
      }

      const centerPx = this.renderedFrame.imageWidth * center / 100;
      // Центр минус половина экрана
      let scrollLeft = centerPx - this.renderedFrame.containerWidth / 2;
      // Учитываем что это может быть вылет за правый край
      // Тогда "прибиваемся" к правому краю
      if (scrollLeft + this.renderedFrame.containerWidth > this.renderedFrame.imageWidth) {
        scrollLeft = this.renderedFrame.imageWidth - this.renderedFrame.containerWidth;
      }

      this.containerElement.scrollLeft = scrollLeft;
      this.containerElement.scrollTop = this.renderedFrame.imageHeight;
    }
  }

  async render(containerWidth, containerHeight, mode) {
    // Ожидаем загрузки всех картинок
    // Делаем все нужные пересчеты и перемещения
    await this.loadImages();
    this.sceneElement.classList.add(this.settings.type);

    // Определить границы по POI в процентах
    let bordersOfPoi = this.getBordersOfPoi();

    // Понять, где центр по POI
    let center = [50, 50];
    if (bordersOfPoi) {
      center = [
        (bordersOfPoi.left + bordersOfPoi.right) / 2,
        (bordersOfPoi.top + bordersOfPoi.bottom) / 2,
      ];
    }

    const imageElement = this.sceneElement.querySelector('img');
    const naturalHeight = imageElement.naturalHeight;
    const naturalWidth = imageElement.naturalWidth;

    // Понять, на сколько нужно отскейлить,
    // чтобы все POI + Gap попали в видимую область
    // и при этом мы не вылезли за nativeWidth/nativeHeight

    let renderedFrame = null;

    const containerRatio = containerWidth / containerHeight;
    const imageRatio = naturalWidth / naturalHeight;

    // Если скролл-мод и изображение шире контейнера, то рендерим для скролла
    if (mode === 'scrollable' && containerRatio < imageRatio) {
      this.sceneElement.classList.add('_scrollable');
      renderedFrame = this.tryRenderScrollable(
        bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight
      );
      this.renderedFrame = renderedFrame;
      this.initScrollPosition();
      this.onSceneScroll();

      this.renderBalloonsAsModals();

      if (this.settings.type === 'line') {
        this.renderBalloonsAsPopovers();
      }
    } else {
      this.sceneElement.classList.remove('_scrollable');

      if (mode === 'contain') {
        renderedFrame = this.tryRenderContain(
          bordersOfPoi, [50, 50], containerWidth, containerHeight, naturalWidth, naturalHeight,
        );
      } else {
        // Сначала смотрим - если изображение по ширине помещается в контейнер + все poi попадают в видимую область - оставляем так
        renderedFrame = this.tryRenderByWidth(
          bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight,
        );

        if (!renderedFrame) {
          renderedFrame = this.tryRenderByHeight(
            bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight,
          );
        }

        // Если вариант по ширине нам не подходит - рендерим по области POI с масштабированием
        if (!renderedFrame && bordersOfPoi) {
          renderedFrame = this.tryRenderByPoi(
            bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight,
          );
        }
      }

      this.renderedFrame = renderedFrame;

      // Попросить спозиционироваться балуны, при этом еще как-то передать им фрейм, относительно которого мы их позиционируем

      this.renderBalloonsAsPopovers();
      setTimeout(() => {
        // Баги рендеров браузеров - иногда нужно пере-отрисовать
        this.renderBalloonsAsPopovers();
      }, 100);
    }
  }

  renderBalloonsAsPopovers() {
    this.objects.forEach((object) => {
      object.renderBalloonAsPopover(
        this.renderedFrame,
      );
    });
  }

  renderBalloonsAsModals() {
    this.objects.forEach((object) => {
      object.renderBalloonAsModal(
        this.renderedFrame,
      );
    });
  }

  // Выбрать границы всех объектов
  // Можно указать типы объектов, которые задаются через [data-type]
  getBordersOfPoi(types) {
    let bordersOfPoi = null;
    this.objects.forEach((object) => {
      if (types && types.indexOf(object.getType()) === -1) {
        // Тип объекта не подходит
        return;
      }
      const objectBorders = object.getBorders();
      if (objectBorders) {
        if (!bordersOfPoi) {
          bordersOfPoi = {
            top: objectBorders.top,
            left: objectBorders.left,
            right: objectBorders.right,
            bottom: objectBorders.bottom,
          };
        }
        bordersOfPoi.top = Math.min(bordersOfPoi.top, objectBorders.top);
        bordersOfPoi.left = Math.min(bordersOfPoi.left, objectBorders.left);
        bordersOfPoi.right = Math.max(bordersOfPoi.right, objectBorders.right);
        bordersOfPoi.bottom = Math.max(bordersOfPoi.bottom, objectBorders.bottom);
      }
    });
    return bordersOfPoi;
  }

  tryRenderScrollable(bordersOfPoi, center,
    containerWidth, containerHeight,
    naturalWidth, naturalHeight) {
    let scale = containerHeight / naturalHeight;

    if (this.isMobileBuildingGenplan()) {
      // scale = 0.7;
    }

    const scaledImageWidth = naturalWidth * scale;
    const scaledImageHeight = naturalHeight * scale;

    let topOfContainer = 0;
    let bottomOfContainer = containerHeight;
    let leftOfContainer = 0;
    let rightOfContainer = scaledImageWidth;

    this.layersElement.style.top = topOfContainer + 'px';
    this.layersElement.style.left = leftOfContainer + 'px';
    this.layersElements.image.style.width = scaledImageWidth + 'px';
    this.layersElements.image.style.height = scaledImageHeight + 'px';
    this.layersElements.lightOnImage.style.width = scaledImageWidth + 'px';
    this.layersElements.lightOnImage.style.height = scaledImageHeight + 'px';

    return {
      top: topOfContainer,
      bottom: bottomOfContainer,
      left: leftOfContainer,
      right: rightOfContainer,
      imageWidth: scaledImageWidth,
      imageHeight: scaledImageHeight,
      containerWidth,
      containerHeight,
    };
  }

  tryRenderContain(bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight) {

    const aspectContainer = containerWidth / containerHeight;
    const aspectImage = naturalWidth / naturalHeight;

    /*
    Основной алгоритм
    например, По ширине:
    Отступ сверху = Половина высоты контейнера - Половина высоты картинки
    Отступ слева = минимум(ширина контейнера, ширина картинки) / 2 - (ширина контейнера) / 2
    */

    // Render by width
    let scale = 1;
    if (aspectContainer > aspectImage) {
      // Render by height
      scale = Math.min(containerHeight / naturalHeight, 1);
    } else {
      // Render by width
      scale = Math.min(containerWidth / naturalWidth, 1);
    }

    const scaledImageWidth = scale * naturalWidth;
    const scaledImageHeight = scale * naturalHeight;

    const topOfContainer = containerHeight / 2 - scaledImageHeight / 2;
    const leftOfContainer = containerWidth / 2 - scaledImageWidth / 2;

    const bottomOfContainer = topOfContainer + containerHeight;
    const rightOfContainer = leftOfContainer + containerWidth;

    this.layersElement.style.top = topOfContainer + 'px';
    this.layersElement.style.left = leftOfContainer + 'px';
    this.layersElements.image.style.width = scaledImageWidth + 'px';
    this.layersElements.image.style.height = scaledImageHeight + 'px';
    this.layersElements.lightOnImage.style.width = scaledImageWidth + 'px';
    this.layersElements.lightOnImage.style.height = scaledImageHeight + 'px';

    return {
      top: topOfContainer,
      bottom: bottomOfContainer,
      left: leftOfContainer,
      right: rightOfContainer,
      imageWidth: scaledImageWidth,
      imageHeight: scaledImageHeight,
      containerWidth,
      containerHeight,
    };
  }

  tryRenderByWidth(bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight) {
    let [centerImageXPercent, centerImageYPercent] = center;

    if (containerWidth > naturalWidth || containerHeight > naturalHeight) {
      console.debug('Could not render by width, container is too large');
      return false;
    }

    const scale = containerWidth / naturalWidth;
    const scaledImageWidth = naturalWidth * scale;
    const scaledImageHeight = naturalHeight * scale;

    if (scaledImageHeight < containerHeight) {
      console.debug('Could not render by width, container is too tall');
      return false;
    }

    const centerXPx = centerImageXPercent / 100 * scaledImageWidth;
    const centerYPx = centerImageYPercent / 100 * scaledImageHeight;

    const centerContainerXPx = containerWidth / 2;
    const centerContainerYPx = containerHeight / 2;

    let topOfContainer = - (centerContainerYPx - centerYPx);
    let bottomOfContainer = topOfContainer + containerHeight;
    let leftOfContainer = 0;
    let rightOfContainer = containerWidth;

    // Если посчитали, что изображение нужно сдвигать вниз и мы увидим дыру сверху, пробуем избежать безобразия
    // и прибить его к верху
    [topOfContainer, bottomOfContainer, leftOfContainer, rightOfContainer] = this.correctOffset(
      topOfContainer,
      bottomOfContainer,
      leftOfContainer,
      rightOfContainer,
      scaledImageWidth,
      scaledImageHeight,
      containerWidth,
      containerHeight,
      bordersOfPoi,
    );

    if (this.isPoiInsideContainer(topOfContainer, bottomOfContainer, leftOfContainer, rightOfContainer, bordersOfPoi, scaledImageWidth, scaledImageHeight)) {
      this.layersElement.style.top = -topOfContainer + 'px';
      this.layersElement.style.left = -leftOfContainer + 'px';
      this.layersElements.image.style.width = scaledImageWidth + 'px';
      this.layersElements.image.style.height = scaledImageHeight + 'px';
      this.layersElements.lightOnImage.style.width = scaledImageWidth + 'px';
      this.layersElements.lightOnImage.style.height = scaledImageHeight + 'px';
      return {
        top: topOfContainer,
        bottom: bottomOfContainer,
        left: leftOfContainer,
        right: rightOfContainer,
        imageWidth: scaledImageWidth,
        imageHeight: scaledImageHeight,
        containerWidth,
        containerHeight,
      };
    }
  }

  tryRenderByHeight(bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight) {
    let [centerImageXPercent, centerImageYPercent] = center;

    if (containerWidth > naturalWidth || containerHeight > naturalHeight) {
      console.debug('Could not render by width, container is too large');
      return false;
    }

    const scale = containerHeight / naturalHeight;
    const scaledImageWidth = naturalWidth * scale;
    const scaledImageHeight = naturalHeight * scale;

    if (scaledImageWidth < containerWidth) {
      console.debug('Could not render by height, container is too wide');
      return false;
    }

    const centerXPx = centerImageXPercent / 100 * scaledImageWidth;
    const centerYPx = centerImageYPercent / 100 * scaledImageHeight;

    const centerContainerXPx = containerWidth / 2;
    const centerContainerYPx = containerHeight / 2;

    let topOfContainer = 0;
    let bottomOfContainer = containerHeight;
    let leftOfContainer = - (centerContainerXPx - centerXPx);
    let rightOfContainer = containerWidth;

    // Если посчитали, что изображение нужно сдвигать вниз и мы увидим дыру сверху, пробуем избежать безобразия
    // и прибить его к верху
    [topOfContainer, bottomOfContainer, leftOfContainer, rightOfContainer] = this.correctOffset(
      topOfContainer,
      bottomOfContainer,
      leftOfContainer,
      rightOfContainer,
      scaledImageWidth,
      scaledImageHeight,
      containerWidth,
      containerHeight,
      bordersOfPoi,
    );

    if (this.isPoiInsideContainer(topOfContainer, bottomOfContainer, leftOfContainer, rightOfContainer, bordersOfPoi, scaledImageWidth, scaledImageHeight)) {
      this.layersElement.style.top = -topOfContainer + 'px';
      this.layersElement.style.left = -leftOfContainer + 'px';
      this.layersElements.image.style.width = scaledImageWidth + 'px';
      this.layersElements.image.style.height = scaledImageHeight + 'px';
      this.layersElements.lightOnImage.style.width = scaledImageWidth + 'px';
      this.layersElements.lightOnImage.style.height = scaledImageHeight + 'px';
      return {
        top: topOfContainer,
        bottom: bottomOfContainer,
        left: leftOfContainer,
        right: rightOfContainer,
        imageWidth: scaledImageWidth,
        imageHeight: scaledImageHeight,
        containerWidth,
        containerHeight,
      };
    }
  }

  tryRenderByPoi(bordersOfPoi, center, containerWidth, containerHeight, naturalWidth, naturalHeight) {
    const [centerImageXPercent, centerImageYPercent] = center;

    const centerContainerXPx = containerWidth / 2;
    const centerContainerYPx = containerHeight / 2;

    // Ставим центр в центр (логически), и считаем на сколько мы можем отмасштабировать картинку,
    // чтобы область POI не вылезла за края видимой области
    // У нас есть: расстояние от центра до верхней и нижней линии в пикселях и в процентах
    //             расстояние от центра до левой и правой линии в пикселях и в процентах - тупо подгоняем одно под другое

    const fromCenterToTopPercent = centerImageYPercent - bordersOfPoi.top;
    const fromCenterToBottomPercent = bordersOfPoi.bottom - centerImageYPercent;
    const fromCenterToLeftPercent = centerImageXPercent - bordersOfPoi.left;
    const fromCenterToRightPercent = bordersOfPoi.right - centerImageXPercent;

    // Отступ от центра до краев контейнера
    const fromCenterByXPx = centerContainerXPx - this.settings.poiGap;
    const fromCenterByYPx = centerContainerYPx - this.settings.poiGap;

    // Посчитать максимальный отступ по x от центра до края области POI - в процентах
    // Посчитать максимальный отступ по y от центра до края области POI- в процентах
    const fromCenterByXPercent = Math.max(fromCenterToLeftPercent, fromCenterToRightPercent);
    const fromCenterByYPercent = Math.max(fromCenterToTopPercent, fromCenterToBottomPercent);
    // Теперь считаем максимально допустимый scale для X оси и для Y оси

    const maxScaledWidthByX = (fromCenterByXPx * 100) / fromCenterByXPercent;
    const maxScaleByX = maxScaledWidthByX / naturalWidth;

    const maxScaledHeightByY = (fromCenterByYPx * 100) / fromCenterByYPercent;
    const maxScaleByY = maxScaledHeightByY / naturalHeight;

    // Выбираем минимальный из рассчитанных коэффициентов увеличения изображения
    let scale = Math.min(maxScaleByX, maxScaleByY);

    // Нормируем увеличение
    // Если требуется увеличение больше чем исходный размер - не даем увеличивать, так как потеряем качество
    if (scale > 1) {
      scale = 1;
    }

    // Считаем ширину и высоту для увеличенного изображения
    const [scaledWidth, scaledHeight] = this.calcScaledSizes(naturalWidth, naturalHeight, scale);

    // Дело за малым - ставим ширину, высоту и смещаем центр изображения
    let centerXPx = (centerImageXPercent / 100) * scaledWidth;
    let centerYPx = (centerImageYPercent / 100) * scaledHeight;

    let centered = false;

    if (scaledWidth <= containerWidth) {
      // Если по ширине изображение целиком входит в экран - ставим его в центр
      centerXPx = (50 / 100) * scaledWidth;
      centered = true;
    }
    if (scaledHeight <= containerHeight) {
      // Если по высоте изображение целиком входит в экран - ставим его в центр
      centerYPx = (50 / 100) * scaledHeight;
      centered = true;
    }

    let topOfContainer = centerYPx - centerContainerYPx;
    let leftOfContainer = centerXPx - centerContainerXPx;
    let bottomOfContainer = topOfContainer + containerHeight;
    let rightOfContainer = leftOfContainer + containerWidth;

    // Если посчитали, что изображение нужно сдвигать и мы дыру, пробуем избежать безобразия
    // и прибить его к краю
    if (!centered) {
      [topOfContainer, bottomOfContainer, leftOfContainer, rightOfContainer] = this.correctOffset(
        topOfContainer,
        bottomOfContainer,
        leftOfContainer,
        rightOfContainer,
        scaledWidth,
        scaledHeight,
        containerWidth,
        containerHeight,
        bordersOfPoi,
      );
    }

    this.layersElement.style.top = -topOfContainer + 'px';
    this.layersElement.style.left = -leftOfContainer + 'px';
    this.layersElements.image.style.width = scaledWidth + 'px';
    this.layersElements.image.style.height = scaledHeight + 'px';
    this.layersElements.lightOnImage.style.width = scaledWidth + 'px';
    this.layersElements.lightOnImage.style.height = scaledHeight + 'px';

    return {
      top: topOfContainer,
      bottom: bottomOfContainer,
      left: leftOfContainer,
      right: rightOfContainer,
      imageWidth: scaledWidth,
      imageHeight: scaledHeight,
      containerWidth,
      containerHeight,
    };
  }

  // Если посчитали, что нужно сдвигать и мы увидим дыру сверху или снизу, пробуем избежать безобразия
  correctOffset(topOfContainer, bottomOfContainer, leftOfContainer, rightOfContainer, scaledImageWidth, scaledImageHeight, containerWidth, containerHeight, bordersOfPoi) {
    let left = leftOfContainer;
    let right = rightOfContainer;
    let top = topOfContainer;
    let bottom = bottomOfContainer;

    if (scaledImageWidth - left >= containerWidth) {
      // Видим дыру слева
      const newLeftOfContainer = 0;
      const newRightOfContainer = containerWidth;

      // После прибития к 0 все точки попадают в область видимости - устанавливаем новые значения
      if (this.isPoiInsideContainer(topOfContainer, bottomOfContainer, newLeftOfContainer, newRightOfContainer, bordersOfPoi, scaledImageWidth, scaledImageHeight)) {
        left = newLeftOfContainer;
        right = newRightOfContainer;
      }
    } else if (scaledImageWidth - left < containerWidth) {
      // Видим дыру справа
      const newLeftOfContainer = scaledImageWidth - containerWidth;
      const newRightOfContainer = newLeftOfContainer + containerWidth;

      // После прибития к 100% все точки попадают в область видимости - устанавливаем новые значения
      if (this.isPoiInsideContainer(topOfContainer, bottomOfContainer, newLeftOfContainer, newRightOfContainer, bordersOfPoi, scaledImageWidth, scaledImageHeight)) {
        left = newLeftOfContainer;
        right = newRightOfContainer;
      }
    }

    if (scaledImageHeight - top >= containerHeight) {
      // Видим дыру вверху
      const newTopOfContainer = 0;
      const newBottomOfContainer = containerHeight;

      // После прибития к 0 все точки попадают в область видимости - устанавливаем новые значения
      if (this.isPoiInsideContainer(newTopOfContainer, newBottomOfContainer, leftOfContainer, rightOfContainer, bordersOfPoi, scaledImageWidth, scaledImageHeight)) {
        top = newTopOfContainer;
        bottom = newBottomOfContainer;
      }
    } else if (scaledImageHeight - top < containerHeight) {
      // Видим дыру внизу
      const newTopOfContainer = scaledImageHeight - containerHeight;
      const newBottomOfContainer = newTopOfContainer + containerHeight;

      // После прибития к 100% все точки попадают в область видимости - устанавливаем новые значения
      if (this.isPoiInsideContainer(newTopOfContainer, newBottomOfContainer, leftOfContainer, rightOfContainer, bordersOfPoi, scaledImageWidth, scaledImageHeight)) {
        top = newTopOfContainer;
        bottom = newBottomOfContainer;
      }
    }

    return [top, bottom, left, right];
  }

  isPoiInsideContainer(topOfContainer, bottomOfContainer, leftOfContainer, rightOfContainer, bordersOfPoi, scaledImageWidth, scaledImageHeight) {
    if (!bordersOfPoi) {
      return true;
    }

    const top = this.calcScaledImageCoordinate(scaledImageHeight, bordersOfPoi.top);
    const bottom = this.calcScaledImageCoordinate(scaledImageHeight, bordersOfPoi.bottom);
    if ((topOfContainer + this.settings.poiGap) > top) {
      console.debug('Есть POI, которые вылезут за верхний край, встраивание по ширине не подходит');
      return false;
    }
    if ((bottomOfContainer - this.settings.poiGap) < bottom) {
      console.debug('Есть POI, которые вылезут за нижний край, встраивание по ширине не подходит');
      return false;
    }

    const left = this.calcScaledImageCoordinate(scaledImageWidth, bordersOfPoi.left);
    const right = this.calcScaledImageCoordinate(scaledImageWidth, bordersOfPoi.right);

    if ((leftOfContainer + this.settings.poiGap) > left) {
      console.debug('Есть POI, которые вылезут за левый край, встраивание по высоте не подходит');
      return false;
    }
    if ((rightOfContainer - this.settings.poiGap) < right) {
      console.debug('Есть POI, которые вылезут за правый край, встраивание по высоте не подходит');
      return false;
    }

    return true;
  }

  calcScaledSizes(width, height, scale) {
    return [
      width * scale,
      height * scale,
    ];
  }

  calcScaledImageCoordinate(dimension, coordinate) {
    return coordinate / 100 * dimension;
  }

  async loadImages() {
    return new Promise((resolve, reject) => {
      // Сколько изображений ожидают загрузки
      let imagesShouldBeLoaded = 0;
      let pending = false;
      const imagesElements = this.sceneElement.querySelectorAll('img');
      imagesElements.forEach((imgElement) => {
        // Изображение уже загружено, пропускаем его
        if (imgElement.complete && imgElement.naturalHeight !== 0) {
          return;
        }
        pending = true;
        imagesShouldBeLoaded++;
        imgElement.onload = () => {
          imagesShouldBeLoaded--;
          if (imagesShouldBeLoaded === 0) {
            resolve();
          }
        };
      });
      // Все изображения уже были загружены
      if (!pending) {
        resolve();
      }
    })
  }

  hide() {
    this.sceneElement.classList.remove('_show');
    this.sceneElement.classList.add('_hide');
  }

  show() {
    this.sceneElement.classList.remove('_hide');
    this.sceneElement.classList.add('_show');
  }

  initListeners() {
    if (window.innerWidth < 1200) {
        return;
    }

    this.objects.forEach((object) => {
      if (!object.polygonElement) {
        return
      }
      const rawCoordinates = object.polygonElement.dataset.coordinates;
      const coordinates = JSON.parse(rawCoordinates);

      object.svgPathPolygonElement.addEventListener('mouseover', () => {
        this.layersElements.clipPath.appendChild(object.createClipSvgElement(coordinates, this.renderedFrame));
        setTimeout(() => {
          this.layersElements.lightOnImage.classList.add('_hovered');
        }, 100)
      })

      object.svgPathPolygonElement.addEventListener('mouseout', () => {
        if (this.layersElements.clipPath.firstChild) {
          setTimeout(() => {
            this.layersElements.clipPath.removeChild(this.layersElements.clipPath.firstChild);
          }, 100);
        }

        this.layersElements.lightOnImage.classList.remove('_hovered');

        setTimeout(() => {
          this.layersElements.lightOnImage.classList.remove('_hovered');
        }, 110);
      })
    })
  }

  initMobileClipObjects() {
    if (window.innerWidth > 1199) {
      return
    }

    let coords = [];

    this.objects.forEach((object) => {
      if (!object.polygonElement) {
        return
      }
      const rawCoordinates = object.polygonElement.dataset.coordinates;
      const coordinates = JSON.parse(rawCoordinates);
      coords.push(coordinates);
    })

    const svg = this.createMobileClipObjects(coords);
    this.layersElements.clipPath.appendChild(svg);
    this.layersElements.lightOnImage.classList.add('_hovered');
  }

  createMobileClipObjects(allCoords) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttributeNS(null, 'width', '0');
    svg.setAttributeNS(null, 'height', '0');
    svg.setAttributeNS(null, 'viewBox', '0 0 100 100');
    svg.setAttributeNS(null, 'preserveAspectRatio', 'none');

    const node = document.createElementNS("http://www.w3.org/2000/svg", 'clipPath');

    node.id = 'light-on-clip-path';

    try {
      allCoords.forEach((coordinates) => {
        const rawCoords = [];
        let polygon = undefined;
        polygon = document.createElementNS("http://www.w3.org/2000/svg", 'polygon');

        coordinates.forEach((coordinate, i) => {
          const [x, y] = coordinate
          rawCoords.push(`${this.renderedFrame.imageWidth * x/100},${this.renderedFrame.imageHeight * y/100}`)
        });

        const coords = rawCoords.join(' ');
        polygon.setAttributeNS(null, 'points', coords);
        node.appendChild(polygon);
      })
    } catch (e) {
      console.log('Got error on build svg node', [
        this.containerElement,
      ]);
    }

    // svg.appendChild(node);

    return svg;
  }

  isMobileBuildingGenplan() {
    return this.settings.type === 'genplan-building' && window.innerWidth < 768;
  }

  initFloorNumbers() {
    if (!this.isMobileBuildingGenplan()) {
      return
    }

    // найдем этажи и добавим им на слой, если они есть
    const floorNumbers = this.sceneElement.querySelectorAll('[data-gen-floor-number]');

    if (floorNumbers) {
      floorNumbers.forEach((floorNumber) => {
        this.setFloorNumberPosition(floorNumber);
        this.layersElements.floorNumbers.appendChild(
            floorNumber,
        );
      })
    } else {
      console.error(`Could not find floor numbers in scene ${  this.name}`);
    }
  }

  setFloorNumberPosition(floorNumber) {
    const rawCoordinates = floorNumber.dataset.floorCoordinates;
    const coordinates = JSON.parse(rawCoordinates);
    let yPos = 0;
    let xPos = 0;

    coordinates.forEach((coordinate) => {
      const [x, y] = coordinate
      yPos += y;

      if (xPos === 0) {
        xPos = x;
      }

      if (x < xPos) {
        xPos = x;
      }
    });

    yPos = yPos / coordinates.length;
    floorNumber.style.top = `${yPos}%`;
    floorNumber.style.left = `${xPos}%`;
    floorNumber.style.transform = 'translate3d(-100%, -25%, 0)';
  }
}
