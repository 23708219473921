import Element from './element';

/**
 * Контейнер - сущность списка элементов аккордиона
 * т.е. это и есть аккордион
 *
 * ОБЯЗАТЕЛЬНО:
 * [data-accordion-container] - вешается на элемент, внутри которого элементы аккордиона
 *
 * ДОПОЛНИТЕЛЬНО:
 * [data-accordion-mode="<режим>"] - список режимов:
 *  single (поумолчанию) - при раскрытии одного элемента, закрывается открытый
 *  multi - можно раскрыть любое количество элементов
 *
 * ПОБОЧНО:
 * ContainerElementOpen - событие, отправляемое на контейнер при разворачивании элемента с передачей элемента
 *
 * ContainerElementClose - событие, отправляемое на контейнер при сворачивании элемента с передачей элемента
 *
 * ПРИМЕР РАЗМЕТКИ КОНТЕЙНЕРА:
 *  <ul data-accordion-container data-accordion-mode="multi">
 *    <li...
 *    (Список элементов. Примеры в классе Element)
 *    ...</li>
 *  </ul>
 *
 *
 * О работе с элементами смотрим класс Element
 */
class Container {
  modes = [
    'single',
    'multi'
  ];
  constructor(containerElement) {
    this.containerElement = containerElement;

    this.elements = [];

    const mode = this.containerElement.dataset.accordionMode;
    if (mode && this.modes.includes(mode)) {
      this.mode = mode;
    } else {
      this.mode = 'single';
    }

    this.init();
  }

  /**
   * Инициализация аккордиона при создании или изменении
   */
  init() {
    this.collect();
    this.elementListeners();
    this.setInitialized();
  }

  /**
   * Сбор всех элементов аккордиона
   */
  collect() {
    const elements = this.containerElement.querySelectorAll('[data-accordion-element]:not([data-initialized="true"])');
    elements.forEach((element) => {
      try {
        this.elements.push(new Element(element));
      } catch (e) {
        console.log(e);
      }
    });
  }

  /**
   * Прослушка элементов
   */
  elementListeners() {
    this.getNotInitContainers().forEach((currentElement) => {
        this.listenOpen(currentElement.element);
        this.listenClose(currentElement.element);
    });
  }

  /**
   * Прослушка события ElementOpen у элемента (событие отправляется только при клике по ссылке внутри элемента)
   */
  listenOpen(currentElement) {
    const instance = this;
    currentElement.addEventListener('ElementOpen', (e) => {
      if (instance.mode === 'single') {

        // Сворачиваем прочие элементы
        instance.elements
          .filter((element) => element.isOpened && element.element !== currentElement)
          .forEach((element) => {
            element.close();
          });
      }

      // Отправляем событие на контейнер
      instance.containerElement.dispatchEvent(new CustomEvent('ContainerElementOpen', {
        detail: {
          element: currentElement
        }
      }));
    });
  }

  /**
   * Прослушка события ElementOpen у элемента (событие отправляется только при клике по ссылке внутри элемента)
   */
  listenClose(currentElement) {
    const instance = this;
    currentElement.addEventListener('ElementOpen', (e) => {
      // Отправляем событие на контейнер
      instance.containerElement.dispatchEvent(new CustomEvent('ContainerElementClose', {
        detail: {
          element: currentElement
        }
      }));
    });
  }

  /**
   * Помечаем элементы как обработанные
   */
  setInitialized() {
    this.getNotInitContainers().forEach(object => object.element.dataset.initialized = "true");
  }

  /**
   * Отправляем ресайз на элементы
   */
  onResize() {
    this.elements.forEach(element => element.onResize());
  }

  /**
   * Хватаем все элементы, которые не помечены как обработанные
   */
  getNotInitContainers() {
    return this.elements.filter(object => !object.element.dataset.initialized);
  }
}

export default Container;