import {onDomChanges} from "../../components/dynamic/observer";

class ModalShareBlock {
  constructor(selector) {
    this.modal = selector;
    if (!this.modal) {
      return;
    }
    this.shareEl = this.modal.querySelector('[data-share]');
    if (!this.shareEl) {
      return;
    }
    this.modalContentEl = this.modal.querySelector('[data-layout-modal]');
    this.type = this.modalContentEl.dataset.type;
    this.id = this.modalContentEl.dataset.id;

    this.shareParams = {
      theme: {
        services: 'vkontakte,odnoklassniki,telegram,viber,whatsapp',
        size: 'm',
        lang: 'ru',
        curtain: true,
        moreButtonType: 'short',
        popupDirection: 'top',
        limit: 0,
        shape: 'round'
      },

      content: {
        url: `${window.location.href}?${this.type}Id=${this.id}`,
      },
    };

    this.init();
  }

  init() {
    if (window.Ya.share2 && this.type && this.id) {
      Ya.share2(this.shareEl, this.shareParams);
    }
  }
}

onDomChanges(() => {
  setTimeout(() => {
    const modal = document.querySelector('._layout-modal');
    // console.log(modal);
    new ModalShareBlock(modal);
  }, 500);
});